import { getCountries } from "@/features";
import { ReactHelmet } from "@/ReactHelmet";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { MyAppWrapper } from "../../../AppContainer/AppWrapper/AppWrapper";
import { Empty, webPage } from "../../../asset/type/type";
import { Section1GlobalPayments } from "./Section1GlobalPayments/Section1GlobalPayments";
import { Section2WhyChooseNeema } from "./Section2WhyChooseNeema/Section2WhyChooseNeema";
import { Section3NeemaGlobalWeb } from "./Section3NeemaGlobalWeb/Section3NeemaGlobalWeb";
import { Section4WeAreNeema } from "./Section4WeAreNeema/Section4WeAreNeema";
import { Section5WeAreNeema } from "./Section5WeAreNeema/Section5WeAreNeema";

const HomePage: React.FC<Empty> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data } = useQuery({
    queryKey: ["destinations", false],
    queryFn: () => getCountries(),
    onError: () => console.log("error"),
  });

  return (
    <div>
      <MyAppWrapper
        element={
          <>
            <ReactHelmet title={"Home page"} description={"Home page"} />
            <Section1GlobalPayments />
            <Section2WhyChooseNeema />
            <Section3NeemaGlobalWeb />
            <Section4WeAreNeema />
            <Section5WeAreNeema />
          </>
        }
        page={{
          page: webPage.homePage.id,
        }}
      />
    </div>
  );
};

export default HomePage;
