import cStyle from "@SHARED_STYLE/Page.module.scss";
import styles from "./Header.module.scss";
import cx from "classnames";
import { text } from "@/asset/text/text";
import countries from "@IMAGE/countries-image.svg";
import countries_mobile from "@IMAGE/countries_mobile.svg";
import { Empty, webPage } from "@/asset/type/type";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import useAnimation from "@/components/useAnimations";

export const Header: React.FC<Empty> = () => {
  const sectionText = text.networkText.header;
  const navigate = useNavigate();
  const _ref = useRef<any>(null);
  useAnimation(_ref, "title");
  return (
    <div className={styles.wrapper}>
      <div className={styles.flex}>
        <div ref={_ref} className={styles.title}>
          {sectionText.title}
        </div>
        <div className={styles.text}>{sectionText.subText}</div>
        <div className={styles.text_desktop}>{sectionText.subText_desktop}</div>
        <div
          onClick={() => {
            if (typeof window !== "undefined") {
              window.lintrk("track", { conversion_id: 16827241 });
            }
            navigate(webPage.contact_us.url);
          }}
          className={cx(cStyle.button, { [cStyle.version5]: true })}
        >
          {sectionText.button}
        </div>
      </div>
      <img className={styles.image} src={countries} alt={"countries"} />
      <img
        className={styles.image_mobile}
        src={countries_mobile}
        alt={"countries"}
      />
    </div>
  );
};
