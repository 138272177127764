import { webPage, WebPageIds } from "@/asset/type/type";

export function isWhiteHeaderVersion(page: WebPageIds, onStartPage: boolean) {
  return (
    page === webPage.contact_us.id ||
    page === webPage.developers.id ||
    (!onStartPage &&
      webPage.map_site.id !== page &&
      webPage.error_404.id !== page) ||
    (page === webPage.network.id && !onStartPage)
  );
}
export function isGreenVersion(page: WebPageIds, onStartPage: boolean) {
  return (
    webPage.error_404.id === page ||
    webPage.map_site.id === page ||
    (page === webPage.network.id && onStartPage)
  );
}
export function isPurpleHeaderVersion(page: WebPageIds, onStartPage: boolean) {
  return page === webPage.about_us.id && onStartPage;
}
export function isWebVersionMenue(page: WebPageIds) {
  return page === webPage.map_site.id;
}

export function isTransperentVersion(page: WebPageIds, onStartPage: boolean) {
  return (
    (onStartPage && page === webPage.error_404.id) ||
    (onStartPage && page === webPage.homePage.id)
  );
}
