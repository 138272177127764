import { apiClient } from "@/lib/api-client";

import { useQuery } from "@tanstack/react-query";

export const getCountries = (): Promise<any[]> => {
  return apiClient.get("/destinations");
};

export const useCountries = () => {
  const { data, isFetching, isFetched } = useQuery({
    queryKey: ["destinations"],
    queryFn: () => getCountries(),
  });

  return {
    data,
    isLoading: isFetching && !isFetched,
  };
};
export const getCountryImage = (nameIso: string): string => {
  return "https://account.getneema.com/images/countries/" + nameIso + ".png";
};
