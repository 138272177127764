import styles from "./Footter.module.scss";
import cStyles from "../../../Component/Page.module.scss";
import cx from "classnames";
import { Empty, webPage } from "../../../asset/type/type";
import { text } from "../../../asset/text/text";
import { FOOTER_PROP } from "./types/footter-types";
import { useNavigate } from "react-router-dom";
import useAnimation from "@/components/useAnimations";
import { useRef } from "react";
const sectionText = text.footerText;
export const Footter: React.FC<FOOTER_PROP> = ({ page_id }) => {
  return (
    <div className={styles.wrapper}>
      {page_id !== webPage.contact_us.id && <Content />}
      <Bottom />
    </div>
  );
};
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lintrk?: any;
  }
}
export const Content: React.FC<Empty> = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.itemWrapper}>
      <div className={styles.title}>{sectionText.title}</div>
      <div className={styles.text}>{sectionText.text}</div>
      <div
        onClick={() => {
          if (typeof window !== "undefined") {
            window.lintrk("track", { conversion_id: 16827241 });
          }
          navigate(webPage.contact_us.url);
        }}
        className={cx(cStyles.button, {
          [cStyles.version5]: true,
        })}
      >
        {sectionText.button}
      </div>
    </div>
  );
};

export const Bottom: React.FC<Empty> = () => {
  return (
    <div className={styles.bottomWrapper}>
      <div className={styles.conditions}>
        <div className={styles.logo}></div>
        <div className={styles.license}>{sectionText.license}</div>

        <div
          onClick={() => window.open("https://getneema.com/en/terms")}
          className={styles.terms}
        >
          {sectionText.terms}
        </div>
      </div>
      <div
        onClick={() => window.open("mailto:global@getneema.com")}
        className={styles.contact}
      >
        <div className={styles.emailImage}></div>

        <div
          onClick={() =>
            window.open("https://www.linkedin.com/company/neema-official/")
          }
          className={styles.linkdinImage}
        ></div>
      </div>
    </div>
  );
};
