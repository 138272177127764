export const contactUsText = {
  form: {
    fullName: "Full name",
    companyBame: "Company name",
    businessEmail: "Business email",
    phoneNumber: "Phone number",
    howCanWeHelpYou: "How can we help you?",
    button: "Submit",
    generalError: "Oops, something went wrong, please try again",
    mandatoryField: "Mandatory field",
    emailFormat:
      "Please enter a valid email address. For example: name@domain.com",
  },
  text: {
    title: "We'd love to get in touch",
    subText: "Our team of experts is here for you",
  },
  ThankYou: "Thank you!",
  touchWithYou: "Our team will soon be in touch with you",
};
