import { text } from "../../../../asset/text/text";
import { Empty, webPage } from "../../../../asset/type/type";
import RestFulApiImage from "../../../../asset/image/RestfulApi.svg";
import styles from "./OurGlobalNetwork.module.scss";
import cStyle from "../../../../Component/Page.module.scss";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import useAnimation from "@/components/useAnimations";
import { useRef } from "react";
const SectionText = text.developersText.section1;
export const OurGlobalNetwork: React.FC<Empty> = () => {
  const navigate = useNavigate();
  const _ref = useRef<any>(null);
  const _ref2 = useRef<any>(null);
  useAnimation(_ref, "title");
  useAnimation(_ref2, "opacity");
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div ref={_ref} className={styles.title}>
          {SectionText.title}
        </div>
        <div className={styles.buttons}>
          <div
            onClick={() => {
              if (typeof window !== "undefined") {
                window.lintrk("track", { conversion_id: 16827241 });
              }
              navigate(webPage.contact_us.url);
            }}
            className={cx(cStyle.button, { [cStyle.version2]: true })}
          >
            {SectionText.requestAccessButton}
          </div>
          <div
            onClick={() => {
              if (typeof window !== "undefined") {
                window.lintrk("track", { conversion_id: 16827289 });
              }
              window.open(
                "https://docs.getneema.com/#global-payouts-introduction"
              );
            }}
            className={cx(cStyle.button, { [cStyle.version3]: true })}
          >
            {SectionText.apiDocumentaionButton}
          </div>
        </div>
      </div>

      <img
        ref={_ref2}
        className={styles.image}
        alt={"Simple to integrate high availability RESTful API."}
        src={RestFulApiImage}
      ></img>
    </div>
  );
};
