import { text } from "@/asset/text/text";
import { Empty } from "@/asset/type/type";

import styles from "./Who-we-are.module.scss";

export const WhoWeAre: React.FC<Empty> = () => {
  return (
    <div className={styles.wrapper}>
      {Object.values(text.companyText.who_we_are_section).map((item) => (
        <div className={styles.textWrapper}>
          <div className={styles.title}>{item.title} </div>
          <div className={styles.text}>{item.text.replace("@", "\n")} </div>
          <div className={styles.text_mobile}>
            {item.text.replace("@", " ")}{" "}
          </div>
        </div>
      ))}
    </div>
  );
};
