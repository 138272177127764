import { text } from "../../../../asset/text/text";
import { Empty } from "../../../../asset/type/type";

import computerImage from "../../../../asset/image/computer.svg";
import computerImageMobile from "../../../../asset/image/computer_mobile.svg";
import styles from "./SendMonyOnApiCall.module.scss";
import cStyle from "../../../../Component/Page.module.scss";
import cx from "classnames";
import useAnimation from "@/components/useAnimations";
import { useRef } from "react";

const SectionText = text.developersText.section2;
export const SendMonyOnApiCall: React.FC<Empty> = () => {
  const _ref = useRef<any>(null);
  useAnimation(_ref, "opacity");
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>{SectionText.title}</div>
        <div className={styles.text}>{SectionText.text}</div>

        <div
          onClick={() => {
            if (typeof window !== "undefined") {
              window.lintrk("track", { conversion_id: 16827289 });
            }
            window.open(
              "https://docs.getneema.com/#global-payouts-introduction"
            );
          }}
          className={cx(cStyle.button, { [cStyle.version2]: true })}
        >
          {SectionText.apiDocumentaionButton}
        </div>
      </div>

      <img
        ref={_ref}
        className={styles.image}
        alt={"Send money worldwide with one API call"}
        src={computerImage}
      ></img>
      <img
        className={styles.imageMobile}
        alt={"Send money worldwide with one API call"}
        src={computerImageMobile}
      ></img>
    </div>
  );
};
