import styles from "./AppWrapper.module.scss";

import React, { useEffect, useState } from "react";
import { webPage, WebPageIds } from "../../asset/type/type";
import { Footter } from "../../Component/ToolBar/Footter/Footter";
import { Header } from "../../Component/ToolBar/Header/Header";
import cx from "classnames";
import {
  isGreenVersion,
  isPurpleHeaderVersion,
  isWhiteHeaderVersion,
} from "./utiles";

export const dbgPrint = true;

export type MyAppWrapperType = {
  element: JSX.Element;
  page: { page: WebPageIds };
};

type APP_WRAPPER_TYPE = {
  finishRender: boolean;
  setFinishRender: React.Dispatch<React.SetStateAction<boolean>> | undefined;
};
export const App_wrapper_provider = React.createContext<APP_WRAPPER_TYPE>({
  finishRender: false,
  setFinishRender: undefined,
});
export const MyAppWrapper: React.FC<MyAppWrapperType> = ({ element, page }) => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const [finishRender, setFinishRender] = useState<boolean>(false);

  useEffect(() => {
    setFinishRender(true);
  }, []);
  const onStartPage = offset === 0;
  useEffect(() => {
    const topStyleItem = document.getElementById("topStyle");
    if (topStyleItem) {
      if (
        page.page === webPage.contact_us.id &&
        window.screen.width > 545 /*without scroll*/
      ) {
        topStyleItem.style.height = "100%";
      } else {
        topStyleItem.style.height = "unset";
      }
    }
  }, []);
  return (
    <App_wrapper_provider.Provider value={{ finishRender, setFinishRender }}>
      <div id={"app_wrapper_id"} className={styles.wrapper}>
        <div
          className={cx(styles.headerWrapper, {
            [styles.white]: isWhiteHeaderVersion(page.page, onStartPage),
            [styles.green]: isGreenVersion(page.page, onStartPage),
            [styles.purpl]: isPurpleHeaderVersion(page.page, onStartPage),
          })}
        >
          <Header onStartPage={onStartPage} pageId={page.page} />
        </div>
        {element}
        {webPage.error_404.id !== page.page &&
          webPage.map_site.id !== page.page && (
            <div>
              <Footter page_id={page.page} />
            </div>
          )}
      </div>
    </App_wrapper_provider.Provider>
  );
};
