import { Empty } from "@/asset/type/type";

import styles from "./Licenses.module.scss";
import licensesImage from "@IMAGE/licenses.svg";
import licensesImageMobile from "@IMAGE/licenses_mobile.svg";
import { text } from "@TEXT/text";
import { useRef } from "react";
import useAnimation from "@/components/useAnimations";

export const Licenses: React.FC<Empty> = () => {
  const _ref = useRef<any>(null);
  useAnimation(_ref, "title");
  return (
    <div className={styles.wrapper}>
      <div ref={_ref} className={styles.title}>
        {text.companyText.Licenses.title}
      </div>
      <img
        className={styles.mobile_image}
        alt={
          "Licenses and certifications : ISO, Capital Market Authority, Israel Securities Authority, GDPR Compliant, Expected Q2 2024 "
        }
        src={licensesImage}
      />
      <img
        className={styles.desktop_image}
        alt={
          "Licenses and certifications : ISO, Capital Market Authority, Israel Securities Authority, GDPR Compliant, Expected Q2 2024 "
        }
        src={licensesImageMobile}
      />
    </div>
  );
};
