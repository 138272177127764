// eslint-disable-next-line no-restricted-imports
import styles from "./Explore-our-network.module.scss";
import { text } from "@/asset/text/text";
import { Empty } from "@/asset/type/type";
import {
  ActionType,
  CONTINENT,
  continentGroup,
  ContinentsProp,
  CountriesListProp,
  CountriesProp,
  destinationGroup,
  DESTINATION_PROP,
} from "./types/explore-our-network-types";
import cx from "classnames";
import { useEffect, useReducer, useRef, useState } from "react";
import {
  isCountryType,
  isContinentType,
  getActiveCountriesByContinent,
} from "./utiles";

import { useQuery } from "@tanstack/react-query";
import { getCountries, getCountryImage } from "./api/getCountries";
import { AvailalbleSendingMethodes } from "./pages/availalble_sending_methodes/Availalble_sending_methodes";
import Loader from "@/components/Loader/Loader";
import useAnimation from "@/components/useAnimations";
import { queryClient } from "@/lib/react-query";

export const Explore_our_network: React.FC<Empty> = () => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const { data, isFetching, isFetched, isLoading, isSuccess, isError } =
    useQuery({
      queryKey: ["destinations", shouldRefresh],
      queryFn: () => getCountries(),
      onError: () => console.log("error"),
    });

  const [destination, setDestination] = useReducer(reducer, null);

  const onContinentClick = (continent: CONTINENT) =>
    setDestination({
      DESTINATION_TYPE: destinationGroup.CONTINENT_TYPE,
      value: continent,
    });
  const onCountryClick = (countryId: number) =>
    setDestination({
      DESTINATION_TYPE: destinationGroup.COUNTRY_TYPE,
      value: countryId,
    });
  useEffect(() => {
    if (isFetched && isSuccess) {
      onContinentClick(continentGroup.EUROPE);
    }
  }, [isLoading, isFetching]);
  //useMemo??
  function reducer(
    current: DESTINATION_PROP,
    action: ActionType
  ): DESTINATION_PROP {
    if (current === null && !isContinentType(action)) {
      return null;
    } else if (current?.continent && isCountryType(action)) {
      const activeCountriesByContinent = getActiveCountriesByContinent(
        current?.continent,
        data ? data : ([] as CountriesListProp)
      );
      const country = activeCountriesByContinent.find(
        (item) => item.country.id === action.value
      );
      return {
        continent: current?.continent,
        country: action.value,
        serviceTypes: country ? country.serviceTypes : [],
      };
    } else if (isContinentType(action)) {
      const activeCountriesByContinent = getActiveCountriesByContinent(
        action.value,
        data ? data : ([] as CountriesListProp)
      );

      return {
        continent: action.value,
        country:
          activeCountriesByContinent.length !== 0
            ? activeCountriesByContinent.sort((a, b) =>
                a.country.name.localeCompare(b.country.name)
              )[0].country.id
            : -1,
        serviceTypes:
          activeCountriesByContinent.length !== 0
            ? activeCountriesByContinent.sort((a, b) =>
                a.country.name.localeCompare(b.country.name)
              )[0].serviceTypes
            : [],
      };
    }
    return null;
  }

  const activeCountriesByContinent = getActiveCountriesByContinent(
    destination ? destination.continent : continentGroup.EUROPE,
    data ? data : ([] as CountriesListProp)
  );

  return (
    <div className={styles.wrapper}>
      <Continents
        isLoading={!isFetched}
        continents={[
          {
            name: continentGroup.EUROPE,
            onClick: () => onContinentClick(continentGroup.EUROPE),
            isActive: destination?.continent === continentGroup.EUROPE,
          },
          {
            name: continentGroup.AMERICAS,
            onClick: () => onContinentClick(continentGroup.AMERICAS),
            isActive: destination?.continent === continentGroup.AMERICAS,
          },

          {
            name: continentGroup.APAC,
            onClick: () => onContinentClick(continentGroup.APAC),
            isActive: destination?.continent === continentGroup.APAC,
          },
          {
            name: continentGroup.MIDDLE_EAST,
            onClick: () => onContinentClick(continentGroup.MIDDLE_EAST),
            isActive: destination?.continent === continentGroup.MIDDLE_EAST,
          },
          {
            name: continentGroup.AFRICA,
            onClick: () => onContinentClick(continentGroup.AFRICA),
            isActive: destination?.continent === continentGroup.AFRICA,
          },
        ]}
      />

      {!isError && isSuccess && (
        <>
          <Countries
            isLoading={!isFetched}
            selectedCountryId={destination?.country}
            countries={activeCountriesByContinent.map((item) => {
              return {
                id: item.country.id,
                name: item.country.name,
                nameIso3: item.country.nameIso3,
                nameIso2: item.country.nameIso2,
                onClick: () => onCountryClick(item.country.id),
              };
            })}
          />
          <AvailalbleSendingMethodes
            isLoading={isLoading}
            methodes={
              destination && destination.serviceTypes
                ? destination.serviceTypes
                : []
            }
          />
        </>
      )}
      {isError && (
        <div
          onClick={() => {
            setShouldRefresh(shouldRefresh);
          }}
          className={styles.errorWrapper}
        >
          <div className={styles.error_text_title}>
            {text.networkText.exploreOurNetwork.error_titl}
          </div>
          <div className={styles.error_text}>
            {text.networkText.exploreOurNetwork.error_Text}
          </div>
        </div>
      )}
    </div>
  );
};

export const Continents: React.FC<ContinentsProp> = ({
  continents,
  isLoading,
}) => {
  const sectionText = text.networkText.exploreOurNetwork;

  const _ref2 = useRef<any>(null);

  useAnimation(_ref2, "opacity", "Continents ", !isLoading);

  return (
    <div className={styles.continentsWrapper}>
      {isLoading && (
        <div className={styles.divWrapper}>{/*  <Loader />*/} </div>
      )}
      <div className={styles.title}>{sectionText.title}</div>
      <div ref={_ref2} className={styles.continents}>
        {continents.map((continent) => (
          <div
            key={continent.name}
            className={cx(styles.continent, {
              [styles.active]: continent.isActive,
            })}
            onClick={() => continent.onClick()}
          >
            {continent.name}{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export const Countries: React.FC<CountriesProp> = ({
  countries,
  isLoading,
  selectedCountryId,
}) => {
  const rowLength = 4;
  const rowLength_mobile = 1;
  const _ref = useRef<any>(null);
  useAnimation(_ref, "opacity", "Continents ", !isLoading);
  return (
    <div ref={_ref} className={styles.countriesWrapper}>
      {countries
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item, index) => (
          <div
            key={item.nameIso3}
            className={cx(styles.countryWrapper, {
              [styles.active]: selectedCountryId === item.id,
              [styles.first_row]: index <= rowLength,
              [styles.first_row_mobile]: index <= rowLength_mobile,
              [styles.next_row_closest_country]:
                index - rowLength - 1 ===
                countries.findIndex((item) => item.id === selectedCountryId),
            })}
          >
            <div className={styles.countryName} onClick={item.onClick}>
              {item.name}
            </div>
            <img
              className={styles.country_image}
              alt={item.name}
              src={getCountryImage(item.nameIso3)}
            />
          </div>
        ))}
    </div>
  );
};
