import cStyles from "../../Component/Page.module.scss";

import { Helmet } from "react-helmet";

import styles from "./mobile-slide.module.scss";

import cx from "classnames";

import { useEffect, useRef, useState } from "react";

import { text } from "@/asset/text/text";
import {
  WhyChooseNeemaMemberType,
  whyChooseNeemaMember,
  Section2WhyChooseNeemaItemProp,
  webPage,
} from "@/asset/type/type";
import { Mobile_slide_prop } from "./types/types/mobile-slide-types";
import { useNavigate } from "react-router-dom";
import useAnimation from "../useAnimations";

export const Mobile_slide: React.FC<Mobile_slide_prop> = ({
  items,
  title,
  button_text,
  textStyle,
}) => {
  const [movementInactive, setMovementInactive] =
    useState<WhyChooseNeemaMemberType>(whyChooseNeemaMember.Security);

  const handleScroll = () => {
    const OurTeamWrapperElement = document.getElementById(
      "whyToChooseNeemaList"
    );
    const scrollPercent = 0.75;
    let currentTeam: WhyChooseNeemaMemberType = whyChooseNeemaMember.Security;

    if (OurTeamWrapperElement) {
      const secondeLocation =
        OurTeamWrapperElement.clientWidth *
        scrollPercent /*% of the show scrol screen we want to scrol( widh+ gap of elemet)*/ *
        1;

      const thiredLocation =
        OurTeamWrapperElement.clientWidth * scrollPercent * 2;
      const forthLocation =
        OurTeamWrapperElement.clientWidth * scrollPercent * 3;

      const maxScrollLeft =
        OurTeamWrapperElement.scrollWidth - OurTeamWrapperElement.clientWidth;

      if (OurTeamWrapperElement.scrollLeft + 1 > secondeLocation) {
        currentTeam = whyChooseNeemaMember.covarge;
      }

      if (
        OurTeamWrapperElement.scrollLeft + 1 > thiredLocation ||
        (maxScrollLeft - OurTeamWrapperElement.scrollLeft < 5 &&
          items.length === 3)
      ) {
        currentTeam = whyChooseNeemaMember.realTime;
      }
      if (
        items.length > 3 &&
        (OurTeamWrapperElement.scrollLeft + 1 > forthLocation ||
          maxScrollLeft - OurTeamWrapperElement.scrollLeft < 5)
      ) {
        console.log("hewe");
        currentTeam = whyChooseNeemaMember.stability;
      }

      setMovementInactive(currentTeam);
    }
  };

  const navigate = useNavigate();
  const _ref = useRef<any>(null);

  useAnimation(_ref, "opacity", "mobile-slide");

  //
  return (
    <div className={styles.wrapper}>
      <meta name="description" content="security great covarge"></meta>
      <div
        className={cx(styles.title, {
          [styles.alignLeft]: items.length > 3,
          [styles.mainTitle]: true,
        })}
      >
        {title}
      </div>

      <div
        ref={_ref}
        id={"whyToChooseNeemaList"}
        onScroll={handleScroll}
        className={styles.whyToChooseNeemaList}
      >
        {items.map((item, index) => (
          <Item
            key={index}
            textStyle={textStyle}
            ImageElement={
              item.image === undefined ? (
                <div className={item.imageClassName}></div>
              ) : (
                <img
                  className={item.imageClassName}
                  alt={item.text}
                  src={item.image}
                ></img>
              )
            }
            text={{ text: item.title, subText: item.text }}
          />
        ))}
      </div>
      <div className={styles.movementImage}>
        <div
          className={cx(styles.movement_moshe, {
            [styles.active]: movementInactive === whyChooseNeemaMember.Security,
          })}
        ></div>
        <div
          className={cx(styles.movement_moshe, {
            [styles.active]: movementInactive === whyChooseNeemaMember.covarge,
          })}
        ></div>
        <div
          className={cx(styles.movement_moshe, {
            [styles.active]: movementInactive === whyChooseNeemaMember.realTime,
          })}
        ></div>

        {items.length >= 4 && (
          <div
            className={cx(styles.movement_moshe, {
              [styles.active]:
                movementInactive === whyChooseNeemaMember.stability,
            })}
          ></div>
        )}
      </div>
      <div className={styles.button_wrapper}>
        {button_text && (
          <div
            onClick={() => {
              if (typeof window !== "undefined") {
                window.lintrk("track", { conversion_id: 16827241 });
              }
              navigate(webPage.contact_us.url);
            }}
            className={cx(cStyles.button, { [cStyles.version2]: true })}
          >
            {button_text}
          </div>
        )}
      </div>
    </div>
  );
};

export const Item: React.FC<Section2WhyChooseNeemaItemProp> = ({
  text,
  ImageElement,
  textStyle,
}) => {
  return (
    <div className={styles.ItemWrapper}>
      {ImageElement}
      <div className={styles.textWrapper}>
        <div className={styles.title}>{text.text}</div>
        <div className={textStyle ? textStyle : styles.text}>
          {text.subText}
        </div>
      </div>
    </div>
  );
};
