import { App_wrapper_provider } from "@/AppContainer/AppWrapper/AppWrapper";
import { useContext, useEffect, useState } from "react";

function useAnimation(
  _ref: React.MutableRefObject<any>,
  type: "opacity" | "title" | "bottom_to_top" | "bottom_to_top_delay",
  key?: string,
  toStart?: boolean
): void {
  const finishRender = useContext(App_wrapper_provider).finishRender;

  useEffect(() => {
    setTimeout(() => {
      if (type === "opacity") {
        _ref.current.style.opacity = 0;
      } else if (type === "title") {
      } else if (type === "bottom_to_top" || type === "bottom_to_top_delay") {
        //   _ref.current.style.paddingTop = "100px";
        //    _ref.current.style.opacity = 0;
      }

      if (_ref.current && (toStart === undefined || toStart)) {
        const element = document.getElementById(`app_wrapper_id`);
        const g = _ref.current.getBoundingClientRect();

        const height = window.innerHeight;
        const zoom = window
          .getComputedStyle(element ? element : _ref.current, null)
          .getPropertyValue("zoom");

        const elementTop = parseFloat(zoom) * g.top;

        setTimeout(() => {
          if (height >= elementTop && finishRender && _ref.current) {
            if ("bottom_to_top_delay") {
              _ref.current.style.transition = "all 1.5s ease";
            } else {
              _ref.current.style.transition = "all 0.8s ease";
            }

            if (type === "opacity") {
              _ref.current.style.opacity = 1;
            } else if (type === "title") {
              _ref.current.style.marginLeft = 0;
            } else if (
              type === "bottom_to_top" ||
              type === "bottom_to_top_delay"
            ) {
              _ref.current.style.opacity = 1;
              _ref.current.style.paddingTop = "0px";
            }
          }
        }, 200);

        window.addEventListener("scroll", () => {
          if (_ref.current) {
            const g = _ref.current.getBoundingClientRect();
            const element = document.getElementById(`app_wrapper_id`);

            const height = window.innerHeight;
            const zoom = window
              .getComputedStyle(element ? element : _ref.current, null)
              .getPropertyValue("zoom");
            const elementTop = parseFloat(zoom) * g.top;

            if (height >= elementTop && finishRender) {
              if ("bottom_to_top_delay") {
                _ref.current.style.transition = "all 1.5s ease";
              } else {
                _ref.current.style.transition = "all 0.8s ease";
              }

              if (type === "opacity") {
                _ref.current.style.opacity = 1;
              } else if (type === "title") {
                _ref.current.style.marginLeft = 0;
              } else if (
                type === "bottom_to_top" ||
                type === "bottom_to_top_delay"
              ) {
                _ref.current.style.opacity = 1;
                _ref.current.style.paddingTop = "0px";
              }
            }
          }
        });
      }
    }, 10);
  }, [finishRender, toStart]);
}

export default useAnimation;
