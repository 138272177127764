import { text } from "../../asset/text/text";
import ERROR_EXIT from "@IMAGE/error_exit.svg";
import ERROR_ICON from "@IMAGE/error_icon.svg";
import SIGN_UP from "@IMAGE/signup.svg";
import styles from "./ContactUs.module.scss";
import cStyles from "../../components/TextStyle.module.scss";
import { ErrorProps, Form_prop } from "./types/types/form-types";
import textStyles from "../../components/TextStyle.module.scss";
import { useEffect, useRef, useState } from "react";
import { Form, isStateValid } from "./pages/form/Form";
import { useLocation } from "react-router-dom";
import { ReactHelmet } from "@/ReactHelmet";
import useAnimation from "@/components/useAnimations";

export const ContactUs: React.FC<Form_prop> = () => {
  const { state, pathname } = useLocation();
  const isError = isStateValid(state) && state.response && state.error;
  const isSucc = isStateValid(state) && state.response && !state.error;
  const [generalError, setGeneralError] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (isError && generalError === false) {
      setGeneralError(true);

      window.history.replaceState(pathname, "");
    }
  }, [state, state && state.response, state && state.error]);
  const _ref = useRef<any>(null);
  useAnimation(_ref, "title");

  return (
    <div className={styles.wrapper}>
      <ReactHelmet title={"Contact us"} description={"Contact us"} />
      {isSucc && (
        <>
          <ContactUsSuccess />
        </>
      )}
      {!isSucc && (
        <>
          <div className={styles.coulm_flex}>
            <div className={textStyles.page_title}>
              <div className={textStyles.small_gap}>
                <div ref={_ref} className={textStyles.title}>
                  {text.contactUsText.text.title}
                </div>
                <div className={textStyles.small_text}>
                  {text.contactUsText.text.subText}
                </div>
              </div>
            </div>
            <div>
              <Form error={generalError} />
            </div>
          </div>

          {generalError && (
            <Error
              setShowEror={(val: boolean) => {
                setGeneralError(val);
                window.history.replaceState(pathname, "");
              }}
              error={text.contactUsText.form.generalError}
            />
          )}
        </>
      )}
    </div>
  );
};

export const ContactUsSuccess: React.FC<Form_prop> = () => {
  const _ref = useRef<any>(null);
  // useAnimation(_ref, "bottom_to_top");
  const _ref2 = useRef<any>(null);
  // useAnimation(_ref2, "bottom_to_top_delay");
  useAnimation(_ref, "opacity");
  useAnimation(_ref2, "opacity");
  return (
    <div className={styles.ContactUsSuccessWrapper}>
      <img
        ref={_ref2}
        className={styles.successSignUpImage}
        src={SIGN_UP}
        alt={SIGN_UP}
      />

      <div ref={_ref} className={styles.ContactUsSuccessTextWrapper}>
        <div className={cStyles.big_title}>{text.contactUsText.ThankYou}</div>
        <div className={styles.text_wrapper}>
          <div className={cStyles.small_text}>
            {text.contactUsText.touchWithYou}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Error: React.FC<ErrorProps> = ({ setShowEror, error }) => {
  return (
    <div className={styles.errorT}>
      <div className={styles.errorContent}>
        <div className={styles.errorContextImg}>
          {" "}
          <img src={ERROR_ICON} alt={ERROR_ICON}></img>{" "}
        </div>
        {error}
      </div>
      <div className={styles.x}>
        <img
          className={styles.errorImage}
          onClick={() => setShowEror(false)}
          src={ERROR_EXIT}
          alt={ERROR_EXIT}
        ></img>
      </div>
    </div>
  );
};
