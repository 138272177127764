export const companyText = {
  header_section: {
    title: "Neema redefines cross-border payments",
    text: "We’re Fintech for Fintechs. Experts in serving FIs/EMIs and Banks. Globally.",
  },
  who_we_are_section: {
    who_we_are: {
      title: "Who we are",
      text: "Our journey at Neema began with a purpose - to bring financial freedom to all.@What started as a startup's dream has now transformed into a global mission.\nIn search of solutions for ourselves, we sought a real-time, wide-coverage, low-cost, never-down cross-border payments network, and found that none existed.\nWe realized that our pain was not unique. Many financial institutions and fintech startups share the same challenge: the gap between their evolving business needs and the capabilities of existing cross-border payment solutions.",
    },
    Our_mission: {
      title: "Our mission",
      text: "With millions of banks and financial institutions and dozens of local payment methods accessible through our network, Neema specializes in providing tailored B2B solutions. We invite financial institutions, NGOs, and other businesses to benefit from our global payment solutions.",
    },
  },

  Licenses: {
    title: "Licenses and certifications",
  },
};
