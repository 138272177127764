export type AvailalbleSendingMethodes_prop = {
  methodes: string[];
  isLoading: boolean;
};

export type CountriesProp = {
  isLoading: boolean;
  selectedCountryId: number | undefined;
  countries: {
    id: number;
    name: string;
    nameIso2: string;
    nameIso3: string;
    onClick: () => void;
  }[];
};

export type ContinentsProp = {
  isLoading: boolean;
  continents: {
    isActive: boolean;
    name: CONTINENT;
    onClick: () => void;
  }[];
};

export type CONTINENT_GROUP = {
  AFRICA: AFRICA;
  EUROPE: EUROPE;
  APAC: APAC;
  MIDDLE_EAST: MIDDLE_EAST;
  AMERICAS: AMERICAS;
};

export const continentGroup: CONTINENT_GROUP = {
  AFRICA: "Africa",
  EUROPE: "Europe",
  APAC: "APAC",
  MIDDLE_EAST: "Middle East",
  AMERICAS: "Americas",
};

export type AFRICA = "Africa";
export type AMERICAS = "Americas";
export type EUROPE = "Europe";
export type APAC = "APAC";
export type MIDDLE_EAST = "Middle East";
export type CONTINENT = AFRICA | AMERICAS | EUROPE | APAC | MIDDLE_EAST;

export type COUNTRY_TYPE = "COUNTRY_TYPE";
export type SERVICE_TYPE = "SERVICE_TYPE";
export type CONTINENT_TYPE = "CONTINENT_TYPE";
export type DESTINATION_TYPE = COUNTRY_TYPE | SERVICE_TYPE | CONTINENT_TYPE;

export const destinationGroup: DESTINATION_TYPE_GROUP = {
  COUNTRY_TYPE: "COUNTRY_TYPE",
  SERVICE_TYPE: "SERVICE_TYPE",
  CONTINENT_TYPE: "CONTINENT_TYPE",
};

export type DESTINATION_TYPE_GROUP = {
  COUNTRY_TYPE: "COUNTRY_TYPE";
  SERVICE_TYPE: "SERVICE_TYPE";
  CONTINENT_TYPE: "CONTINENT_TYPE";
};

export type DESTINATION_PROP = {
  continent: CONTINENT;
  country: number;
  serviceTypes: string[];
} | null;

export type CountriesListProp = {
  country: { name: string; nameIso3: string; nameIso2: string; id: number };
  serviceTypes: string[];
}[];

export type ActionType =
  | {
      DESTINATION_TYPE: COUNTRY_TYPE;
      value: number;
    }
  | {
      DESTINATION_TYPE: CONTINENT_TYPE;
      value: CONTINENT;
    };

export type DestinationActionType = {
  DESTINATION_TYPE: COUNTRY_TYPE;
  value: number;
};
export type ContinentActionType = {
  DESTINATION_TYPE: CONTINENT_TYPE;
  value: CONTINENT;
};

export type ServiceTypeActionType = {
  DESTINATION_TYPE: SERVICE_TYPE;
  value: string;
};
