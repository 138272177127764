import { text } from "@/asset/text/text";
import {
  COUNTRY_TYPE,
  destinationGroup,
  CONTINENT_TYPE,
  SERVICE_TYPE,
  DESTINATION_TYPE,
  ActionType,
  DestinationActionType,
  ContinentActionType,
  ServiceTypeActionType,
  CONTINENT,
  continentGroup,
  CountriesListProp,
} from "./types/explore-our-network-types";

import bankAccountImage from "@IMAGE/bankAccountImage.svg";

import cardsImage from "@IMAGE/cardsImage.svg";
import cashPickUp from "@IMAGE/cashPickUp.svg";
import institutionImage from "@IMAGE/institutionImage.svg";
import mobileWallwtOrupi from "@IMAGE/mobileWallwtOrupi.svg";

export function isCountryType(
  DESTINATION_TYPE: ActionType
): DESTINATION_TYPE is DestinationActionType {
  return (
    (DESTINATION_TYPE as DestinationActionType).DESTINATION_TYPE ===
    destinationGroup.COUNTRY_TYPE
  );
}

export function isContinentType(
  DESTINATION_TYPE: ActionType
): DESTINATION_TYPE is ContinentActionType {
  return (
    (DESTINATION_TYPE as ContinentActionType).DESTINATION_TYPE ===
    destinationGroup.CONTINENT_TYPE
  );
}

const africaCountrieMap = new Map(
  text.africaCountries.map((country) => [country.iso3, country.countryName])
);

const americasCountriesMap = new Map(
  text.americasCountries.map((country) => [country.iso3, country.countryName])
);
const europeCountriesMap = new Map(
  text.europe.map((country) => [country.iso3, country.countryName])
);
const apacCountriesCountriesMap = new Map(
  text.apacCountries.map((country) => [country.iso3, country.countryName])
);
const middleEastCountriesMap = new Map(
  text.middleEast.map((country) => [country.iso3, country.countryName])
);

export function getActiveCountriesByContinent(
  continent: CONTINENT,
  allCountries: CountriesListProp
): CountriesListProp {
  if (continent === continentGroup.AFRICA) {
    return allCountries.filter(
      (item) => africaCountrieMap.get(item.country.nameIso3) != undefined
    );
  } else if (continent === continentGroup.AMERICAS) {
    return allCountries.filter((item) => {
      return americasCountriesMap.get(item.country.nameIso3) != undefined;
    });
  } else if (continent === continentGroup.EUROPE) {
    return allCountries.filter((item) => {
      return europeCountriesMap.get(item.country.nameIso3) != undefined;
    });
  } else if (continent === continentGroup.APAC) {
    return allCountries.filter((item) => {
      return apacCountriesCountriesMap.get(item.country.nameIso3) != undefined;
    });
  } else if (continent === continentGroup.MIDDLE_EAST) {
    return allCountries.filter((item) => {
      return middleEastCountriesMap.get(item.country.nameIso3) != undefined;
    });
  }
  //
  throw new Error("continent not implemented.");
}

export const methodesMap = new Map([
  [
    "BankAccount",
    { text: text.networkText.bankAccount, image: bankAccountImage },
  ],
  ["Cards", { text: text.networkText.cards, image: cardsImage }],
  ["CashPickup", { text: text.networkText.cashPickup, image: cashPickUp }],
  ["MobileWallet", { text: text.networkText.mobileWallet, image: cashPickUp }],
  [
    "InstitutionPayment",
    { text: text.networkText.institutionPayment, image: institutionImage },
  ],
  ["Upi", { text: text.networkText.upi, image: mobileWallwtOrupi }],
]);
