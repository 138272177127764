export const africaCountries = [
  { countryName: "Algeria", code: 12, iso3: "DZA" },
  { countryName: "Angola", code: 24, iso3: "AGO" },
  { countryName: "Benin", code: 204, iso3: "BEN" },
  { countryName: "Botswana", code: 72, iso3: "BWA" },
  { countryName: "Burkina Faso", code: 854, iso3: "BFA" },
  { countryName: "Burundi", code: 108, iso3: "BDI" },
  { countryName: "Cameroon", code: 120, iso3: "CMR" },
  { countryName: "Cape Verde", code: 132, iso3: "CPV" },
  { countryName: "Central African Republic", code: 140, iso3: "CAF" },
  { countryName: "Chad", code: 148, iso3: "TCD" },
  { countryName: "Comoros", code: 174, iso3: "COM" },
  { countryName: "Democratic Republic of the Congo", code: 180, iso3: "COD" },
  { countryName: "Djibouti", code: 262, iso3: "DJI" },
  { countryName: "Egypt", code: 818, iso3: "EGY" },
  { countryName: "Equatorial Guinea", code: 226, iso3: "GNQ" },
  { countryName: "Eritrea", code: 232, iso3: "ERI" },
  { countryName: "Eswatini", code: 748, iso3: "SWZ" },
  { countryName: "Ethiopia", code: 231, iso3: "ETH" },
  { countryName: "Gabon", code: 266, iso3: "GAB" },
  { countryName: "Gambia", code: 270, iso3: "GMB" },
  { countryName: "Ghana", code: 936, iso3: "GHA" },
  { countryName: "Guinea", code: 324, iso3: "GIN" },
  { countryName: "Guinea-Bissau", code: 624, iso3: "GNB" },
  { countryName: "Ivory Coast", code: 384, iso3: "CIV" },
  { countryName: "Kenya", code: 404, iso3: "KEN" },
  { countryName: "Lesotho", code: 426, iso3: "LSO" },
  { countryName: "Liberia", code: 430, iso3: "LBR" },
  { countryName: "Libya", code: 434, iso3: "LBY" },
  { countryName: "Madagascar", code: 450, iso3: "MDG" },
  { countryName: "Malawi", code: 454, iso3: "MWI" },
  { countryName: "Mali", code: 466, iso3: "MLI" },
  { countryName: "Mauritania", code: 478, iso3: "MRT" },
  { countryName: "Mauritius", code: 480, iso3: "MUS" },
  { countryName: "Morocco", code: 504, iso3: "MAR" },
  { countryName: "Mozambique", code: 508, iso3: "MOZ" },
  { countryName: "Namibia", code: 516, iso3: "NAM" },
  { countryName: "Niger", code: 562, iso3: "NER" },
  { countryName: "Nigeria", code: 566, iso3: "NGA" },
  { countryName: "Rwanda", code: 646, iso3: "RWA" },
  { countryName: "Sao Tome and Principe", code: 239, iso3: "STP" },
  { countryName: "Senegal", code: 686, iso3: "SEN" },
  { countryName: "Seychelles", code: 690, iso3: "SYC" },
  { countryName: "Sierra Leone", code: 694, iso3: "SLE" },
  { countryName: "Somalia", code: 706, iso3: "SOM" },
  { countryName: "South Africa", code: 710, iso3: "ZAF" },
  { countryName: "South Sudan", code: 728, iso3: "SSD" },
  { countryName: "Sudan", code: 729, iso3: "SDN" },
  { countryName: "Tanzania", code: 834, iso3: "TZA" },
  { countryName: "Togo", code: 768, iso3: "TGO" },
  { countryName: "Tunisia", code: 788, iso3: "TUN" },
  { countryName: "Uganda", code: 800, iso3: "UGA" },
  { countryName: "Zambia", code: 894, iso3: "ZMB" },
  { countryName: "Zimbabwe", code: 716, iso3: "ZWE" },
];

export const networkText = {
  exploreOurNetwork: {
    title: "Explore our network",
    error_titl: "Oops, something went wrong",
    error_Text: "Please refresh the page",
    availalbleSendingMethods: {
      title: "Available sending methods",
      sendingMethods: {
        bankAccount: "Bank Account",
        mobileWallet: "Mobile Wallet",
        cashPickup: "Cash Pickup",
        institutionPayment: "Institution Payment",
        UPI: "UPI",
        Cards: "Cards",
      },
    },
  },

  header: {
    title: "Global transactions,\ninfinite possibilities",
    subText:
      "With Neema Global, the world is your financial playground. Seamlessly send money to over 100 destination countries",
    subText_desktop:
      "Seamlessly send money to near infinite\ncountry-currency-payment method combinations",

    button: "Get started",
  },
  bankAccount: "Bank Account",
  cashPickup: "Cash Pickup",
  mobileWallet: "Mobile Wallet",
  institutionPayment: "Institution Payment",
  upi: "UPI",
  cards: "Cards",
};

export const americasCountries = [
  { countryName: "Antigua and Barbuda", code: 28, iso3: "ATG" },
  { countryName: "Argentina", code: 32, iso3: "ARG" },
  { countryName: "Bahamas", code: 44, iso3: "BHS" },
  { countryName: "Barbados", code: 52, iso3: "BRB" },
  { countryName: "Belize", code: 84, iso3: "BLZ" },
  { countryName: "Bolivia", code: 68, iso3: "BOL" },
  { countryName: "Brazil", code: 76, iso3: "BRA" },
  { countryName: "Canada", code: 124, iso3: "CAN" },
  { countryName: "Chile", code: 152, iso3: "CHL" },
  { countryName: "Colombia", code: 170, iso3: "COL" },
  { countryName: "Costa Rica", code: 188, iso3: "CRI" },
  { countryName: "Cuba", code: 192, iso3: "CUB" },
  { countryName: "Dominica", code: 212, iso3: "DMA" },
  { countryName: "Dominican Republic", code: 214, iso3: "DOM" },
  { countryName: "Ecuador", code: 218, iso3: "ECU" },
  { countryName: "El Salvador", code: 222, iso3: "SLV" },
  { countryName: "Grenada", code: 308, iso3: "GRD" },
  { countryName: "Guatemala", code: 320, iso3: "GTM" },
  { countryName: "Guyana", code: 328, iso3: "GUY" },
  { countryName: "Haiti", code: 332, iso3: "HTI" },
  { countryName: "Honduras", code: 340, iso3: "HND" },
  { countryName: "Jamaica", code: 388, iso3: "JAM" },
  { countryName: "Mexico", code: 484, iso3: "MEX" },
  { countryName: "Nicaragua", code: 558, iso3: "NIC" },
  { countryName: "Panama", code: 591, iso3: "PAN" },
  { countryName: "Paraguay", code: 600, iso3: "PRY" },
  { countryName: "Peru", code: 604, iso3: "PER" },
  { countryName: "Saint Kitts and Nevis", code: 659, iso3: "KNA" },
  { countryName: "Saint Lucia", code: 662, iso3: "LCA" },
  { countryName: "Saint Vincent and the Grenadines", code: 670, iso3: "VCT" },
  { countryName: "Suriname", code: 740, iso3: "SUR" },
  { countryName: "Trinidad and Tobago", code: 780, iso3: "TTO" },
  { countryName: "United States", code: 840, iso3: "USA" },
  { countryName: "Uruguay", code: 858, iso3: "URY" },
  { countryName: "Venezuela", code: 862, iso3: "VEN" },
];
//35 remove from apac - middle east
export const apacCountries = [
  { countryName: "Australia", code: 36, iso3: "AUS" },
  { countryName: "Azerbaijan", code: 31, iso3: "AZE" },

  { countryName: "Bangladesh", code: 50, iso3: "BGD" },
  { countryName: "Bhutan", code: 64, iso3: "BTN" },
  { countryName: "Brunei", code: 96, iso3: "BRN" },
  { countryName: "Cambodia", code: 116, iso3: "KHM" },
  { countryName: "China", code: 156, iso3: "CHN" },
  { countryName: "Hong Kong", code: 344, iso3: "HKG" },
  { countryName: "India", code: 356, iso3: "IND" },
  { countryName: "Indonesia", code: 360, iso3: "IDN" },

  { countryName: "Japan", code: 392, iso3: "JPN" },

  { countryName: "Kazakhstan", code: 398, iso3: "" },

  { countryName: "Kyrgyzstan", code: 417, iso3: "KGZ" },
  { countryName: "Laos", code: 418, iso3: "LAO" },

  { countryName: "Malaysia", code: 458, iso3: "MYS" },
  { countryName: "Maldives", code: 462, iso3: "MDV" },
  { countryName: "Mongolia", code: 496, iso3: "MNG" },
  { countryName: "Myanmar", code: 104, iso3: "MMR" },
  { countryName: "Nepal", code: 524, iso3: "NPL" },
  { countryName: "New Zealand", code: 554, iso3: "NZL" },
  { countryName: "North Korea", code: 408, iso3: "PRK" },

  { countryName: "Pakistan", code: 586, iso3: "PAK" },

  { countryName: "Philippines", code: 608, iso3: "PHL" },
  { countryName: "Qatar", code: 634, iso3: "QAT" },

  { countryName: "Singapore", code: 702, iso3: "SGP" },
  { countryName: "South Korea", code: 410, iso3: "KOR" },
  { countryName: "Sri Lanka", code: 144, iso3: "LKA" },

  { countryName: "Taiwan", code: 158, iso3: "TWN" },
  { countryName: "Tajikistan", code: 762, iso3: "TJK" },
  { countryName: "Thailand", code: 764, iso3: "THA" },
  { countryName: "Timor-Leste", code: 626, iso3: "TLS" },
  { countryName: "Turkmenistan", code: 795, iso3: "TKM" },
  { countryName: "United Arab Emirates", code: 784, iso3: "ARE" },
  { countryName: "Uzbekistan", code: 860, iso3: "UZB" },
  { countryName: "Vietnam", code: 704, iso3: "VNM" },
];

export const middleEast = [
  { countryName: "Afghanistan", code: 4, iso3: "AFG" },
  { countryName: "Bahrain", code: 48, iso3: "BHR" },
  { countryName: "Iran", code: 364, iso3: "IRN" },
  { countryName: "Iraq", code: 368, iso3: "IRQ" },
  { countryName: "Israel", code: 376, iso3: "ISR" },
  { countryName: "Jordan", code: 400, iso3: "JOR" },
  { countryName: "Kuwait", code: 414, iso3: "KWT" },
  { countryName: "Lebanon", code: 422, iso3: "LBN" },
  { countryName: "Oman", code: 512, iso3: "OMN" },

  { countryName: "Saudi Arabia", code: 682, iso3: "SAU" },
  { countryName: "Syria", code: 760, iso3: "SYR" },

  { countryName: "Yemen", code: 887, iso3: "YEM" },
];

export const europe = [
  { countryName: "Albania", code: 8, iso3: "ALB" },
  { countryName: "Andorra", code: 20, iso3: "AND" },
  { countryName: "Armenia", code: 51, iso3: "ARM" },
  { countryName: "Austria", code: 40, iso3: "AUT" },
  { countryName: "Azerbaijan", code: 31, iso3: "AZE" },
  { countryName: "Belarus", code: 112, iso3: "BLR" },
  { countryName: "Belgium", code: 56, iso3: "BEL" },
  { countryName: "Bosnia and Herzegovina", code: 70, iso3: "BIH" },
  { countryName: "Bulgaria", code: 100, iso3: "BGR" },
  { countryName: "Croatia", code: 191, iso3: "HRV" },
  { countryName: "Cyprus", code: 196, iso3: "CYP" },
  { countryName: "Czech Republic", code: 203, iso3: "CZE" },
  { countryName: "Denmark", code: 208, iso3: "DNK" },
  { countryName: "Estonia", code: 233, iso3: "EST" },
  { countryName: "Finland", code: 358, iso3: "FIN" },
  { countryName: "France", code: 250, iso3: "FRA" },
  { countryName: "Georgia", code: 268, iso3: "GEO" },
  { countryName: "Germany", code: 276, iso3: "DEU" },
  { countryName: "Greece", code: 300, iso3: "GRC" },
  { countryName: "Hungary", code: 348, iso3: "HUN" },
  { countryName: "Iceland", code: 352, iso3: "ISL" },
  { countryName: "Ireland", code: 372, iso3: "IRL" },
  { countryName: "Italy", code: 380, iso3: "ITA" },
  { countryName: "Kazakhstan", code: 398, iso3: "KAZ" },
  { countryName: "Kosovo", code: 383, iso3: "XKX" },
  { countryName: "Latvia", code: 428, iso3: "LVA" },
  { countryName: "Liechtenstein", code: 438, iso3: "LIE" },
  { countryName: "Lithuania", code: 440, iso3: "LTU" },
  { countryName: "Luxembourg", code: 442, iso3: "LUX" },
  { countryName: "Malta ", code: 470, iso3: "MLT" },
  { countryName: "Moldova", code: 498, iso3: "MDA" },
  { countryName: "Monaco", code: 492, iso3: "MCO" },
  { countryName: "Montenegro", code: 499, iso3: "MNE" },
  { countryName: "Netherlands", code: 528, iso3: "NLD" },
  { countryName: "North Macedonia", code: 807, iso3: "MKD" },
  { countryName: "Norway", code: 578, iso3: "NOR" },
  { countryName: "Poland", code: 616, iso3: "POL" },
  { countryName: "Portugal", code: 620, iso3: "PRT" },
  { countryName: "Romania", code: 642, iso3: "ROU" },
  { countryName: "Russia", code: 643, iso3: "RUS" },
  { countryName: "San Marino", code: 674, iso3: "SMR" },
  { countryName: "Serbia", code: 688, iso3: "SRB" },
  { countryName: "Slovakia ", code: 703, iso3: "SVK" },
  { countryName: "Slovenia", code: 705, iso3: "SVN" },
  { countryName: "Spain", code: 724, iso3: "ESP" },
  { countryName: "Sweden", code: 752, iso3: "SWE" },
  { countryName: "Switzerland", code: 756, iso3: "CHE" },
  { countryName: "Turkey", code: 792, iso3: "TUR" },
  { countryName: "Ukraine", code: 804, iso3: "UKR" },
  { countryName: "United Kingdom", code: 826, iso3: "GBR" },
  { countryName: "Vatican City", code: 379, iso3: "VAT" },
];
