import { text } from "../../../../asset/text/text";
import {
  Empty,
  Section5WeAreNeemaInvestoreProp,
  Section5WeAreNeemaTeamMemberProp,
  team,
  TeamMemberType,
} from "../../../../asset/type/type";

import styles from "./Section5WeAreNeema.module.scss";
import cx from "classnames";
import { useRef, useState } from "react";
import useAnimation from "@/components/useAnimations";
const sectionText = text.homePageText.section5;
export const Section5WeAreNeema: React.FC<Empty> = () => {
  const width = window.innerWidth;
  const _ref2 = useRef<any>(null);

  useAnimation(_ref2, "title", "dddd", width > 545);

  return (
    <div className={styles.wrapper}>
      <div
        ref={_ref2}
        className={cx(styles.title, {
          [styles.top]: true,
          [styles.main_top]: true,
        })}
      >
        {sectionText.title}
      </div>
      <div className={styles.content}>
        <OurInvestors />
        <OurTeam />
      </div>
    </div>
  );
};

const OurInvestors: React.FC<Empty> = () => {
  const outTeam = sectionText.section3;
  return (
    <div className={styles.ourInvestorsWrapper}>
      <div className={styles.textWrapper}>
        <div className={styles.title}>{sectionText.section1.title}</div>

        <div className={styles.text}>{sectionText.section1.subText}</div>
      </div>

      <div className={styles.investors}>
        <div className={styles.investorsWrapper}>
          <div className={styles.flex}></div>
          <Investor
            ImageElement={<div className={styles.directImage}></div>}
            text={{
              text: outTeam[2].subText,

              title: outTeam[2].text,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Investor: React.FC<Section5WeAreNeemaInvestoreProp> = ({
  text,
  ImageElement,
}) => {
  return (
    <div className={styles.investorMember}>
      {ImageElement}
      <div className={styles.textWrapper}>
        <div className={styles.title}>{text.title}</div>
        <div className={styles.text}>{text.text}</div>
      </div>
    </div>
  );
};
const OurTeam: React.FC<Empty> = () => {
  const outTeam = sectionText.section2.OurTeam;
  const OurTeamWrapperElement = document.getElementById("OurTeamWrapper");
  if (OurTeamWrapperElement) {
  }
  const [movementInactive, setMovementInactive] = useState<TeamMemberType>(
    team.DORON
  );

  const shiraRef = useRef(null);
  const mosheRef = useRef(null);
  const yuvalRef = useRef(null);
  const asafRef = useRef(null);
  const doronRef = useRef(null);

  const handleScroll = () => {
    const OurTeamWrapperElement = document.getElementById("OurTeamWrapper");

    let currentTeam: TeamMemberType = team.DORON;
    if (OurTeamWrapperElement) {
      const mosheLocation = OurTeamWrapperElement.clientWidth * 0.6845 * 1;
      const yuvalLocation = OurTeamWrapperElement.clientWidth * 0.6845 * 2;
      const asafLocation = OurTeamWrapperElement.clientWidth * 0.6845 * 3;
      const shiraLocation = OurTeamWrapperElement.clientWidth * 0.6845 * 4;

      const maxScrollLeft =
        OurTeamWrapperElement.scrollWidth - OurTeamWrapperElement.clientWidth;

      if (OurTeamWrapperElement.scrollLeft > mosheLocation) {
        currentTeam = team.MOSHE;
      }
      if (OurTeamWrapperElement.scrollLeft > yuvalLocation) {
        currentTeam = team.YOVAL;
      }
      if (OurTeamWrapperElement.scrollLeft > asafLocation) {
        currentTeam = team.ASAF;
      }
      if (
        OurTeamWrapperElement.scrollLeft > shiraLocation ||
        maxScrollLeft === OurTeamWrapperElement.scrollLeft
      ) {
        currentTeam = team.SHIRA;
      }
      setMovementInactive(currentTeam);
    }
  };
  const _ref2 = useRef<any>(null);
  useAnimation(_ref2, "opacity");
  return (
    <div className={styles.ourTeam}>
      <div className={styles.titleWrapper}>
        <div className={cx(styles.title, { [styles.top]: true })}>
          {" "}
          {sectionText.section2.title}
        </div>
      </div>
      <div
        ref={_ref2}
        onScroll={() => {
          handleScroll();
        }}
        id={"OurTeamWrapper"}
        className={styles.OurTeamWrapper}
      >
        <TeamMember
          ImageElement={<div className={styles.doronImage}></div>}
          text={{
            text: outTeam[0].text,
            subText: outTeam[0].subText,
            title: outTeam[0].title,
          }}
          _ref={doronRef}
        />

        <TeamMember
          ImageElement={<div className={styles.mosheImage}></div>}
          _ref={mosheRef}
          text={{
            text: outTeam[1].text,
            subText: outTeam[1].subText,
            title: outTeam[1].title,
          }}
          url={"https://www.linkedin.com/in/moshe-kimhi-394288a3/"}
        />
        <TeamMember
          ImageElement={<div className={styles.yuvalImage}></div>}
          _ref={yuvalRef}
          text={{
            text: outTeam[2].text,
            subText: outTeam[2].subText,
            title: outTeam[2].title,
          }}
          url={"https://www.linkedin.com/in/yuval-giveon/"}
        />
        <TeamMember
          ImageElement={<div className={styles.asafImage}></div>}
          text={{
            text: outTeam[3].text,
            subText: outTeam[3].subText,
            title: outTeam[3].title,
          }}
          _ref={asafRef}
          url={"https://www.linkedin.com/in/asaf-zucker-92074620b/"}
        />
        <TeamMember
          ImageElement={<div className={styles.shiraImage}></div>}
          _ref={shiraRef}
          text={{
            text: outTeam[4].text,
            subText: outTeam[4].subText,
            title: outTeam[4].title,
          }}
          url={"https://www.linkedin.com/in/shira-meoded-92395493/"}
        />
      </div>
      <div className={styles.movementImage}>
        <div
          className={cx(styles.movement_moshe, {
            [styles.active]: movementInactive === team.DORON,
          })}
        ></div>
        <div
          className={cx(styles.movement_moshe, {
            [styles.active]: movementInactive === team.MOSHE,
          })}
        ></div>
        <div
          className={cx(styles.movement_moshe, {
            [styles.active]: movementInactive === team.YOVAL,
          })}
        ></div>
        <div
          className={cx(styles.movement_moshe, {
            [styles.active]: movementInactive === team.ASAF,
          })}
        ></div>
        <div
          className={cx(styles.movement_moshe, {
            [styles.active]: movementInactive === team.SHIRA,
          })}
        ></div>
      </div>
    </div>
  );
};

const TeamMember: React.FC<Section5WeAreNeemaTeamMemberProp> = ({
  text,
  ImageElement,
  url,
  _ref,
}) => {
  return (
    <div ref={_ref} className={styles.teamMember}>
      {ImageElement}
      <div className={styles.textWrapper}>
        <div className={styles.title}>{text.title}</div>
        <div className={styles.text_linkdin_wrapper}>
          <div className={styles.text}></div>
          {url && (
            <div
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.lintrk("track", { conversion_id: 16827249 });
                }
                window.open(url);
              }}
              className={styles.linkdinImage}
            ></div>
          )}
          {text.text}
        </div>
        <div className={styles.subText}>{text.subText}</div>
      </div>
    </div>
  );
};
