import { text } from "../../../../asset/text/text";
import {
  Empty,
  NeemaAmountProp,
  NumberAnimationType,
  webPage,
} from "../../../../asset/type/type";
import cx from "classnames";
import styles from "./Section1GlobalPayments.module.scss";
import cStyles from "../../../../Component/Page.module.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { App_wrapper_provider } from "@/AppContainer/AppWrapper/AppWrapper";
import useAnimation from "@/components/useAnimations";

const Section1GlobalPaymentsText = text.homePageText.section1;
export const Section1GlobalPayments: React.FC<Empty> = () => {
  const numbersInfo = Section1GlobalPaymentsText.neemaAmountInfo;
  const [numbers, setNumbers] = useState<NumberAnimationType>([
    { start: 0, id: numbersInfo.destinations.id },
    { start: 0, id: numbersInfo.currencies.id },
    { start: 0, id: numbersInfo.TransactionsDelivered.id },
    { start: 0, id: numbersInfo.FinancialInstitutions.id },
  ]);
  const finishRender = useContext(App_wrapper_provider).finishRender;

  useEffect(
    () => {
      const timer = setTimeout(
        () =>
          setNumbers((numbers: NumberAnimationType) => {
            return numbers.map((number) => {
              const currentNumberInfo = Object.values(numbersInfo).find(
                (item) => item.id === number.id
              );

              const returnValue = {
                start:
                  currentNumberInfo && currentNumberInfo.amount > number.start
                    ? parseFloat(
                        (number.start + currentNumberInfo.amount / 25).toFixed(
                          2
                        )
                      )
                    : currentNumberInfo
                    ? currentNumberInfo.amount
                    : 0,

                id: number.id,
              };
              return returnValue;
            });
          }),
        40
      );

      return () => {
        clearTimeout(timer);
      };
    },
    numbers.map((item) => item.start)
  );

  const navigate = useNavigate();
  const _ref = useRef<any>(null);
  useAnimation(_ref, "title");
  return (
    <div className={cx(styles.wrapper, {})}>
      <div className={styles.header}>
        <div ref={_ref} className={styles.title}>
          {Section1GlobalPaymentsText.title}
        </div>
        <div
          onClick={() => {
            if (typeof window !== "undefined") {
              window.lintrk("track", { conversion_id: 16827241 });
            }
            navigate(webPage.contact_us.url);
          }}
          className={cx(cStyles.button, {
            [cStyles.version5]: true,
          })}
        >
          {Section1GlobalPaymentsText.button}
        </div>
      </div>

      <div className={styles.neemaAmountInfo}>
        {Object.values(numbers).map((neemaAmount, index) => {
          const currentNumberInfo = Object.values(numbersInfo).find(
            (item) => item.id === neemaAmount.id
          );

          return (
            <NeemaAmount
              id={index}
              amount={`${neemaAmount.start}${currentNumberInfo?.sign}`}
              text={currentNumberInfo ? currentNumberInfo.text : ""}
            />
          );
        })}
      </div>
    </div>
  );
};

export const NeemaAmount: React.FC<NeemaAmountProp> = ({
  amount,
  text,
  id,
}) => {
  return (
    <div className={styles.neemaAmountWrapper} key={id}>
      <div className={styles.amount}>{amount}</div>
      <div className={styles.amountText}>{text}</div>
    </div>
  );
};
