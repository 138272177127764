// eslint-disable-next-line no-restricted-imports
import { Form_prop } from "@/features/contactUs/types/types/form-types";
import cStyle from "@SHARED_STYLE/page.module.scss";
import styles from "./Network.module.scss";
import cx from "classnames";
import { text } from "@/asset/text/text";
import { Header } from "./header/Header";
import { MyAppWrapper } from "@/AppContainer/AppWrapper/AppWrapper";
import { webPage } from "@/asset/type/type";
import { Explore_our_network } from "./explore-our-network";
import { useEffect } from "react";
import { ReactHelmet } from "@/ReactHelmet";

export const Network: React.FC<Form_prop> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MyAppWrapper
      element={
        <>
          <div className={styles.wrapper}>
            <ReactHelmet title={"Network"} description={"Network"} />
            <Header />
            <Explore_our_network />
          </div>
        </>
      }
      page={{
        page: webPage.network.id,
      }}
    />
  );
};
