import { text } from "@/asset/text/text";
import { webPage } from "@/asset/type/type";
import Loader from "@/components/Loader/Loader";
import cx from "classnames";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreatePayoutPortalRegistrationRequest } from "../..";

import { Form_pro, Form_prop } from "../../types/types/form-types";
import styles from "./Form.module.scss";
import { ButtonItem } from "./pages/button/button";
import { buttons_colors } from "./pages/button/types/button-types";
import { Input } from "./pages/Input/Input";
import { varientGroup } from "./pages/Input/types/Input-types";
export const emailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i
);
export function isStateValid(
  state: undefined | { error: boolean; response: boolean }
): state is { error: boolean; response: boolean } {
  if (!state) return false;
  if (!state.response) return false;

  return true;
}
export const Form: React.FC<Form_pro> = ({ error }) => {
  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [fullNumber, setFullNumber] = useState<string>();
  const [description, setDescription] = useState<string>();
  const navigate = useNavigate();

  const [load, setLoad] = useState<boolean>(false);

  const createPayoutPortalRegistrationRequest =
    useCreatePayoutPortalRegistrationRequest({
      onSuccess: () =>
        navigate(webPage.contact_us.url, {
          state: { error: false, response: true },
        }),
      onFailure: () => {
        setLoad(false);
        navigate(webPage.contact_us.url, {
          state: { error: true, response: true },
        });
      },
      request: {
        fullName: name ? name : "",
        businessEmail: email ? email : "",
        companyName: companyName ? companyName : "",
        phoneNumber: fullNumber ? fullNumber : "",
        description: description ? description : "",
      },
    });

  const isFormInputsInvalid =
    email &&
    email.length != 0 &&
    emailRegex.test(email) &&
    name &&
    name.length != 0 &&
    companyName &&
    companyName.length != 0 &&
    fullNumber &&
    fullNumber.length != 0 &&
    description &&
    description.length != 0;
  const onsubmit = () => {
    setOnButtonClick(true);
    if (isFormInputsInvalid) {
      setLoad(true);
      createPayoutPortalRegistrationRequest.submit();
    }
  };
  const formText = text.contactUsText.form;
  const [onButtonClick, setOnButtonClick] = useState(false);

  return (
    <div className={cx(styles.wrapper, { [styles.onError]: error })}>
      <div className={styles.inputsWrapper}>
        <div className={styles.normal_input_varent}>
          <Input
            title={text.contactUsText.form.fullName}
            varient={varientGroup.NORMAL_BOX_V1}
            onChange={setName}
            value={name}
            error={
              name && name.length !== 0 ? undefined : formText.mandatoryField
            }
            onButtonClick={onButtonClick}
          />

          <Input
            title={text.contactUsText.form.companyBame}
            varient={varientGroup.NORMAL_BOX_V1}
            onChange={setCompanyName}
            value={companyName}
            error={
              companyName && companyName.length !== 0
                ? undefined
                : formText.mandatoryField
            }
            onButtonClick={onButtonClick}
          />

          <Input
            title={text.contactUsText.form.phoneNumber}
            varient={varientGroup.IN_LARGE_BOX_ROW}
            onChange={setFullNumber}
            value={fullNumber}
            error={
              fullNumber && fullNumber.length !== 0
                ? undefined
                : formText.mandatoryField
            }
            onButtonClick={onButtonClick}
          />
          <Input
            title={text.contactUsText.form.businessEmail}
            varient={varientGroup.IN_LARGE_BOX_ROW}
            onChange={setEmail}
            value={email}
            error={
              email && emailRegex.test(email) ? undefined : formText.emailFormat
            }
            onButtonClick={onButtonClick}
          />
        </div>
        <Input
          title={text.contactUsText.form.howCanWeHelpYou}
          varient={varientGroup.LARGE_BOX_V1}
          onChange={setDescription}
          value={description}
          error={
            description && description.length != 0
              ? undefined
              : formText.mandatoryField
          }
          onButtonClick={onButtonClick}
        />
      </div>
      <div className={styles.loadWrapper}>
        <ButtonItem
          text={text.contactUsText.form.button}
          color={buttons_colors.WHITE}
          backgrounColor={buttons_colors.PURPLE}
          onChange={() => onsubmit()}
        />
        {load && <Loader />}
      </div>
    </div>
  );
};
