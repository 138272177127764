import { text } from "../../../../asset/text/text";
import {
  Empty,
  Section3NeemaGlobalWebProp,
  webPage,
} from "../../../../asset/type/type";

import styles from "./Section3NeemaGlobalWeb.module.scss";
import cStyles from "../../../../Component/Page.module.scss";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import useAnimation from "@/components/useAnimations";
const SectionText = text.homePageText.section3;
export const Section3NeemaGlobalWeb: React.FC<Empty> = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        <Item
          ImageElement={<div className={styles.amlImage}></div>}
          text={SectionText.aml}
          extraElement={<></>}
          box_size={{ layout: "LARGE" }}
        />
        <Item
          ImageElement={
            <div className={styles.countlessPossibilitiesImage}></div>
          }
          text={SectionText.CountlessPossibilities}
          extraElement={<ExtraElement />}
          box_size={{ text: "LARGE" }}
        />
        <Item
          ImageElement={<div className={styles.overNightImage}></div>}
          text={SectionText.overNight}
          extraElement={<></>}
        />
        <Item
          ImageElement={<div className={styles.neverDownImage}></div>}
          text={SectionText.NeverDown}
          extraElement={<></>}
        />
      </div>
    </div>
  );
};

export const Item: React.FC<Section3NeemaGlobalWebProp> = ({
  text,
  ImageElement,
  extraElement,
  box_size,
}) => {
  const _ref = useRef<any>(null);
  useAnimation(_ref, "opacity", text.text);
  return (
    <div
      ref={_ref}
      className={cx(styles.itemWrapper, {
        [styles.large]: extraElement !== <></>,
      })}
    >
      {ImageElement}
      <div
        className={cx(styles.sideWrapper, {
          [styles.large]: box_size?.layout === "LARGE",
        })}
      >
        <div className={styles.textWrapper}>
          <div className={styles.title}>{text.text}</div>
          <div
            className={cx(styles.text, {
              [styles.large]: box_size?.text === "LARGE",
            })}
          >
            {text.subText}
          </div>
        </div>
        {extraElement}
      </div>
    </div>
  );
};

export const ExtraElement: React.FC<Empty> = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.extraElementWrapper}>
      <div className={styles.countries}>
        {SectionText.CountlessPossibilities.cuntries.map((country) => (
          <div className={styles.country}>{country} </div>
        ))}
      </div>
      <div
        onClick={() => navigate(webPage.network.url)}
        className={cx(cStyles.button, { [cStyles.version2]: true })}
      >
        {SectionText.CountlessPossibilities.button}
      </div>
    </div>
  );
};
