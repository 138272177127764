import { text } from "../../../../asset/text/text";
import {
  Empty,
  Section4NeemaGlobalWebProp,
  webPage,
} from "../../../../asset/type/type";

import styles from "./Section4WeAreNeema.module.scss";
import cStyles from "../../../../Component/Page.module.scss";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import useAnimation from "@/components/useAnimations";
import { useRef } from "react";
const sectionText = text.homePageText.section4;
export const Section4WeAreNeema: React.FC<Empty> = () => {
  const _ref2 = useRef<any>(null);
  useAnimation(_ref2, "opacity");
  return (
    <div className={styles.wrapper}>
      <Image
        ImageElement={
          <div ref={_ref2} className={styles.payoutPortalImage}></div>
        }
        text={{
          text: sectionText.title,
          subText: {
            firstRow: sectionText.subText,
            secondRow: sectionText.subTextSecondRow,
            thirdRow: sectionText.subTextThirdRow,
            bold: sectionText.subTextSecondRowBold,
          },
        }}
        extraElement={<></>}
      />
    </div>
  );
};

export const Image: React.FC<Section4NeemaGlobalWebProp> = ({
  text,
  ImageElement,
  extraElement,
}) => {
  const navigate = useNavigate();
  const _refTitle = useRef<any>(null);

  useAnimation(_refTitle, "title", text.text);

  return (
    <div className={styles.itemWrapper}>
      <div className={styles.sideWrapper}>
        <div className={styles.textWrapper}>
          <div ref={_refTitle} className={styles.title}>
            {text.text}
          </div>
          <div className={styles.subTextWrapper}>
            <div className={styles.our_to}>{text.subText.firstRow}</div>

            <div className={styles.watch_the}>
              {text.subText.secondRow + " " + text.subText.thirdRow}
            </div>

            <div className={styles.in_time}>{text.subText.bold}</div>
          </div>

          <div className={styles.subTextWrapper_desktop}>
            <div className={styles.our_to}>
              {text.subText.firstRow + " " + text.subText.secondRow}
            </div>

            <div className={styles.row_flex}>
              <div className={styles.status_transfer}>
                {text.subText.thirdRow}
              </div>

              <div className={styles.in_time}>{text.subText.bold}</div>
            </div>
          </div>
        </div>
        {extraElement}
      </div>
      <div
        onClick={() => {
          if (typeof window !== "undefined") {
            window.lintrk("track", { conversion_id: 16827241 });
          }
          navigate(webPage.contact_us.url);
        }}
        className={cx(cStyles.button, { [cStyles.version1]: true })}
      >
        {sectionText.button}
      </div>
      {ImageElement}
    </div>
  );
};

export const ExtraElement: React.FC<Empty> = () => {
  return <div className={styles.extraElementWrapper}></div>;
};
