import { ReactHelmet } from "@/ReactHelmet";
import { useEffect } from "react";
import { MyAppWrapper } from "../../../AppContainer/AppWrapper/AppWrapper";
import { Empty, webPage } from "../../../asset/type/type";
import { OurGlobalNetwork } from "./OurGlobalNetwork/OurGlobalNetwork";
import { RestfulApi } from "./RestfulApi/RestfulApi";
import { SendMonyOnApiCall } from "./SendMonyOnApiCall/SendMonyOnApiCall";

const Developers: React.FC<Empty> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ReactHelmet title={"Developers"} description={"Developers"} />
      <MyAppWrapper
        element={
          <>
            <OurGlobalNetwork />
            <SendMonyOnApiCall />
            <RestfulApi />
          </>
        }
        page={{
          page: webPage.developers.id,
        }}
      />
    </div>
  );
};

export default Developers;
