import { text } from "../../../../asset/text/text";
import { Empty } from "../../../../asset/type/type";

import { Mobile_slide } from "@/components/mobile-slide/mobile-slide";

import styles from "./RestfulApi.module.scss";
const textSection = text.developersText.section3;
export const RestfulApi: React.FC<Empty> = () => {
  return (
    <div className={styles.wrapper}>
      <Mobile_slide
        title={textSection.title}
        items={[
          textSection.SecureByDesign,
          textSection.SimpleAPIs,
          textSection.realTime,
        ]}
        textStyle={textSection.textStyle}
      />
    </div>
  );
};

/*
 <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.topTitle}>{textSection.title}</div>
        <div className={styles.buttons}>
          <div className={styles.requestAccessButton}></div>
          <div className={styles.apiDecumentationButton}></div>
        </div>
      </div>
      <div className={styles.images}>
        <div>
          <img
            className={styles.image}
            alt={"Secure by design"}
            src={lockImage}
          ></img>
          <div className={styles.textWrapper}>
            <div className={styles.title}>
              {textSection.SecureByDesign.title}
            </div>
            <div className={styles.text}>{textSection.SecureByDesign.text}</div>
          </div>
        </div>
        <div>
          <img
            className={styles.image}
            alt={"SSimple APIs for everything you need"}
            src={simpleApiImage}
          ></img>
          <div className={styles.textWrapper}>
            <div className={styles.title}>{textSection.SimpleAPIs.title}</div>
            <div className={styles.text}>{textSection.SimpleAPIs.text}</div>
          </div>
        </div>
        <div>
          <img
            className={styles.image}
            alt={"Real time transaction status updates"}
            src={realTimeImage}
          ></img>
          <div className={styles.textWrapper}>
            <div className={styles.title}>{textSection.realTime.title}</div>
            <div className={styles.text}>{textSection.realTime.text}</div>
          </div>
        </div>
      </div>
    </div>
*/
