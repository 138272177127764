export type Empty = Record<string, never>;

type WebPageProp = {
  homePage: { id: "HOME_PAGE"; url: "/" };
  pay_out_portal: {
    id: "PAYOUT_PORTAL";
    url: "https://payouts-portal.getneema.com";
  };
  developers: { id: "DEVELOPERS"; url: "/developers" };
  error_404: { id: "NOT_FOUND_PAGE_404"; url: "/page-not-found" };
  contact_us: { id: "CONTACT_US"; url: "/contact-us" };
  about_us: { id: "ABOUT_US"; url: "/company/about-us" };
  network: { id: "NETWORK"; url: "/network" };
  map_site: { id: "MAP_SITE"; url: "/map-site" };
};
export type NOT_FOUND_PAGE_404 = "NOT_FOUND_PAGE_404";
export type HOME_PAGE = "HOME_PAGE";

export type DEVELOPERS = "DEVELOPERS";
export type CONTACT_US = "CONTACT_US";
export type ABOUT_US = "ABOUT_US";
export type NETWORK = "NETWORK";
export type MAP_SITE = "MAP_SITE";
export type WebPageIds =
  | HOME_PAGE
  | DEVELOPERS
  | NOT_FOUND_PAGE_404
  | CONTACT_US
  | ABOUT_US
  | NETWORK
  | MAP_SITE;

export type MOSHE = "MOSHE";
export type YOVAL = "YOVAL";
export type ASAF = "ASAF";
export type SHIRA = "SHIRA";
export type DORON = "DORON";
export type TeamMemberType = MOSHE | YOVAL | ASAF | SHIRA | DORON;
export type Security = "Security";
export type covarge = "covarge";
export type realTime = "realTime";
export type stability = "stability";

export type WhyChooseNeemaMemberType =
  | Security
  | covarge
  | realTime
  | stability;

export const team: Team = {
  MOSHE: "MOSHE",
  ASAF: "ASAF",
  SHIRA: "SHIRA",
  DORON: "DORON",
  YOVAL: "YOVAL",
};

export const whyChooseNeemaMember: WhyChooseNeemaMember = {
  Security: "Security",
  covarge: "covarge",
  realTime: "realTime",
  stability: "stability",
};

export type WhyChooseNeemaMember = {
  Security: Security;
  covarge: covarge;
  realTime: realTime;
  stability: stability;
};

export type Team = {
  MOSHE: MOSHE;
  ASAF: ASAF;
  SHIRA: SHIRA;
  DORON: DORON;
  YOVAL: YOVAL;
};

export const webPage: WebPageProp = {
  homePage: { id: "HOME_PAGE", url: "/" },
  developers: { id: "DEVELOPERS", url: "/developers" },
  error_404: { id: "NOT_FOUND_PAGE_404", url: "/page-not-found" },
  contact_us: { id: "CONTACT_US", url: "/contact-us" },
  about_us: { id: "ABOUT_US", url: "/company/about-us" },
  network: { id: "NETWORK", url: "/network" },
  map_site: { id: "MAP_SITE", url: "/map-site" },
  pay_out_portal: {
    id: "PAYOUT_PORTAL",
    url: "https://payouts-portal.getneema.com",
  },
};

export type NeemaAmountProp = {
  amount: string;
  text: string;
  id: number;
};
//ffff
export type HeaderProp = {
  onStartPage: boolean;
  pageId: WebPageIds;
};
export type Section2WhyChooseNeemaItemProp = {
  ImageElement: JSX.Element;
  text: { text: string; subText: string };
  textStyle?: string;
};

export type ReactHelmetProp = {
  title: string;
  description: string;
};

export type Section3NeemaGlobalWebProp = {
  ImageElement: JSX.Element;
  text: { text: string; subText: string };
  extraElement: JSX.Element;
  box_size?: { layout?: "SMALL" | "LARGE"; text?: "SMALL" | "LARGE" };
};
export type Section4NeemaGlobalWebProp = {
  ImageElement: JSX.Element;
  text: {
    text: string;
    subText: {
      firstRow: string;
      secondRow: string;
      thirdRow: string;
      bold: string;
    };
  };
  extraElement: JSX.Element;
};

export type Section5WeAreNeemaTeamMemberProp = {
  ImageElement: JSX.Element;
  text: { text: string; subText: string; title: string };
  url?: string;
  _ref: any;
};

export type Section5WeAreNeemaInvestoreProp = {
  ImageElement: JSX.Element;
  text: {
    text: string;
    title: string;
  };
};

// <T> &

export type NumberAnimationType = {
  id: number;
  start: number;
}[];
