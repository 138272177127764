import { webPage } from "@/asset/type/type";
import { Navigator_mobile } from "@/Component/ToolBar/Navigator_mobile/Navigator_mobile";
import { Network } from "@/features";
import { Company_about_as } from "@/features/company";
import { ContactUs } from "@/features/contactUs";

import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Developers from "../Component/Pages/Developers/Developers";
import HomePage from "../Component/Pages/HomePage/HomePage";
import Page404 from "../Component/Pages/Page404/Page404";
import Terms from "../Component/Pages/Terms/Terms";
import { MyAppWrapper } from "./AppWrapper/AppWrapper";
//TODO
function RoutesCollection() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/map-site"
          element={
            <MyAppWrapper
              element={<Navigator_mobile />}
              page={{
                page: webPage.map_site.id,
              }}
            />
          }
        />

        <Route path="/developers" element={<Developers />} />
        <Route path="/" element={<HomePage />} />

        <Route
          path="/contact-us"
          element={
            <MyAppWrapper
              element={<ContactUs />}
              page={{
                page: webPage.contact_us.id,
              }}
            />
          }
        />
        <Route path="/company/about-us" element={<Company_about_as />} />
        <Route path="/network" element={<Network />} />
        <Route path="/page-not-found" element={<Page404 />} />

        {
          <Route
            path="/*"
            element={<Navigate replace to="/page-not-found" />}
          />
        }
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesCollection;
