import { MyAppWrapper } from "../../../AppContainer/AppWrapper/AppWrapper";
import { text } from "../../../asset/text/text";
import { Empty, webPage } from "../../../asset/type/type";
import styles from "./Page404.module.scss";

import error404CoinImage from "../../../asset/image/coins.svg";
import error404CoinImage_nobile from "@IMAGE/background/page404Background_curreny_mobile.svg";
import cStyle from "../../../Component/Page.module.scss";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
const sectionText = text.notFoundPageText;
//ssssss
const Page404: React.FC<Empty> = () => {
  return (
    <div>
      <MyAppWrapper
        element={
          <>
            <div className={styles.contentWrapper}></div>
          </>
        }
        page={{
          page: webPage.error_404.id,
        }}
      />
      {<Page404Image />}
    </div>
  );
};

export default Page404;

const Page404Image: React.FC<Empty> = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page404Wrapper}>
      <div className={styles.content}>
        <div className={styles.fullImage}>
          <img
            className={styles.errorCurrency_mobile}
            alt={"404errorCurrency"}
            src={error404CoinImage_nobile}
          ></img>

          <img
            className={styles.errorCurrency_desktop}
            alt={"404errorCurrency"}
            src={error404CoinImage}
          ></img>
        </div>
      </div>
      <div className={styles.text}>
        <div className={styles.title}>{sectionText.title}</div>
        <div
          onClick={() => navigate(webPage.homePage.url)}
          className={cx(cStyle.button, { [cStyle.version4]: true })}
        >
          {sectionText.button}
        </div>
      </div>
    </div>
  );
};
