import lockImage from "../../../asset/image/lock_440.svg";
import realTimeImage from "../../../asset/image/real_tim_440.svg";
import simpleApiImage from "../../../asset/image/simple_epi_440.svg";
import styles from "../../../components/mobile-slide/mobile-slide.module.scss";

export const developersText = {
  section1: {
    title: "Simple to integrate high availability RESTful API.",
    requestAccessButton: "Request access",
    apiDocumentaionButton: "API documentation",
  },
  section2: {
    title: "Send money worldwide with one API call",
    text: "One API integration opens a world of endless country-currency-payment methods combinations.",
    apiDocumentaionButton: "API documentation",
  },
  section3: {
    textStyle: styles.textVersion1,
    title: "Our global network",
    GetStarted: "Get started",
    SecureByDesign: {
      title: "Secure by design",
      text: "As a financial services license holder, we’re committed to the highest level of IT security and data privacy protection.",
      image: lockImage,
      imageClassName: styles.imageV1,
    },
    SimpleAPIs: {
      title: "Simple APIs for everything you need",
      text: "Get real time currency rates, new payment methods, transaction status updates and account balance with light, simple and modern RESTful API.",
      image: simpleApiImage,
      imageClassName: styles.imageV1,
    },
    realTime: {
      title: "Real time transaction status updates",
      text: "With our webhooks or via API, real time status updates and available at all times.",
      image: realTimeImage,
      imageClassName: styles.imageV1,
    },
  },
};
