import { MyAppWrapper } from "@/AppContainer/AppWrapper/AppWrapper";
import { Empty, webPage } from "@/asset/type/type";

import { Section5WeAreNeema } from "@/Component/Pages/HomePage/Section5WeAreNeema/Section5WeAreNeema";
import { ReactHelmet } from "@/ReactHelmet";
import { useEffect } from "react";

import styles from "./Company.module.scss";
import { Header } from "./header/Header";
import { WhoWeAre } from "./licenses/Who-we-are";
import { Licenses } from "./Licenses2/Licenses";

export const Company_about_as: React.FC<Empty> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MyAppWrapper
      element={
        <>
          <div className={styles.wrapper}>
            <ReactHelmet
              title={"About the company"}
              description={"About the company"}
            />
            <Header />

            <WhoWeAre />
            <Section5WeAreNeema />
            <Licenses />
          </div>
        </>
      }
      page={{
        page: webPage.about_us.id,
      }}
    />
  );
};
