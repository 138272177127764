import React from "react";

import styles from "./Loader.module.scss";

class Loader extends React.Component {
  static defaultProps = {
    size: 80,
  };

  render() {
    //loader
    return <div className={styles.loader}></div>;
  }
}

export default Loader;
