import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "../lib/react-query";
import RoutesCollection from "./Route";

export default function AppContainer() {
  return (
    <QueryClientProvider client={queryClient}>
      {<ReactQueryDevtools initialIsOpen={false} />}
      <RoutesCollection />
    </QueryClientProvider>
  );
}
/*
 
*/
