import { Input_prop, varientGroup } from "./types/Input-types";
import styles from "./Input.module.scss";
import textStyles from "../../../../../../components/TextStyle.module.scss";
import cx from "classnames";

import ERROR_ICON from "@IMAGE/error_icon.svg";
import { useState } from "react";
export const Input: React.FC<Input_prop> = ({
  title,
  onChange,
  varient,
  value,
  error,
  onButtonClick,
}) => {
  const [onBluer, setOnBluer] = useState(false);

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.LARGE_BOX_V1]: varient === varientGroup.LARGE_BOX_V1,
        [styles.IN_LARGE_BOX_ROW]: varient === varientGroup.IN_LARGE_BOX_ROW,
      })}
    >
      <div className={textStyles.tiny_text}>{title}</div>
      {varient !== varientGroup.LARGE_BOX_V1 && (
        <input
          value={value}
          onChange={(event) => onChange(event.target.value)}
          type={"text"}
          className={cx(styles.box, {
            [styles.error]: (onBluer || onButtonClick) && error?.length,
          })}
          onBlur={(e) => {
            e.preventDefault();

            setOnBluer(true);
          }}
        />
      )}
      {varient === varientGroup.LARGE_BOX_V1 && (
        <textarea
          onBlur={(e) => {
            e.preventDefault();

            setOnBluer(true);
          }}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className={cx(styles.box, {
            [styles.LARGE_BOX_V1]: true,
            [styles.error]: (onBluer || onButtonClick) && error,
          })}
        />
      )}
      {(onBluer || onButtonClick) && error && (
        <div className={styles.errorWrapper}>
          <img
            className={styles.errorContextImg}
            src={ERROR_ICON}
            alt={"error"}
          />
          <div className={styles.errorWarning}> {error} </div>
        </div>
      )}
    </div>
  );
};
