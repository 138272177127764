import styles from "../../../components/mobile-slide/mobile-slide.module.scss";

export const homePageText = {
  section1: {
    title: "Global Payments Network for Businesses",
    button: "Get started",
    neemaAmountInfo: {
      currencies: {
        text: "Supported\ncurrencies",
        amount: 50,
        sign: "+",
        id: 4,
      },
      TransactionsDelivered: {
        text: "Transactions delivered in less than 2 minutes",
        amount: 90,
        sign: "%",
        id: 1,
      },
      FinancialInstitutions: {
        text: "Accessible\naccounts",
        amount: 7.2,
        sign: "B+",
        id: 2,
      },
      destinations: {
        text: "Destination\ncountries",
        amount: 150,
        sign: "+",
        id: 3,
      },
    },
  },
  section2: {
    title: "Why choose Neema",
    button: "Get started",
    WhyChooseNeema: {
      SecurityCompliance: {
        title: "Security & compliance",
        text: "Compliance and AML in mind, AI based transaction monitoring is a part of every transaction.",
        imageClassName: styles.SecurityComplianceImage,
      },
      GreatCovarge: {
        title: "Great coverage",
        text: "Countless countries, currencies and local payment methods.",
        imageClassName: styles.GreatCovargeImage,
      },
      RealTimeTransfers: {
        title: "Real time transfers",
        text: "Wide real-time or same day coverage.",
        imageClassName: styles.RealTimeTransfersImage,
      },
      RocksolidStability: {
        title: "Rock solid stability",
        text: "We are never down. Built to ensure every transaction always goes through.",
        imageClassName: styles.RocksolidStabilityImage,
      },
    },
  },
  //
  section3: {
    aml: {
      text: "AML & compliance included",
      subText:
        "Global reach, local confidence. Every transaction goes through our screening and monitoring systems to make sure you have a peace of mind.",
    },
    overNight: {
      text: "Overnight",
      subText:
        "Every minute matters, so your money is never ‘in transit’. Wide real-time or same-day coverage.",
    },
    CountlessPossibilities: {
      text: "Countless possibilities",
      subText:
        "With millions of banks, digital wallets, cards and cash pickup locations in our network, your money can reach every corner on the planet!",
      button: "Our network",
      cuntries: ["Europe", "Americas", "APAC", "Middle east", "Africa"],
    },
    NeverDown: {
      text: "Never down",
      subText:
        "Built in within our network is our dynamic routing technology, combined with real time fail-safe. That way, we can ensure our transactions always go through.",
    },
  },
  section4: {
    title: "Advanced web portal for full transparency",
    button: "Get started",
    subText: "Our web based portal enables you to",
    subTextSecondRow: "watch the",
    subTextThirdRow: "status of every transfer.",
    subTextSecondRowBold: "In real time.",
  },
  section5: {
    title: "We are Neema",

    section1: {
      title: "Our investors",
      button: "Get started",
      subText:
        "Neema is a YC company (2017) and was picked as most promising startup in Mobile Summit 2017. \nWe are backed by one of Israel’s leading financial companies.",
    },
    section2: {
      title: "Our team",
      button: "Get started",
      OurTeam: [
        {
          title: "Doron Schneidman",
          text: "Chairman",
          subText:
            "CEO, Direct Insurance Financial Investments, one of the largest non-bank financial institutions in Israel. ",
        },
        {
          title: "Moshe Kimhi",
          text: "CEO, Founder",
          subText:
            "C.P.A / MBA from Jerusalem & Tel Aviv universities.\nMoshe is an experienced\nfinancial executive, former PwC. Leading Neema for the past 5 years.",
        },
        {
          title: "Yuval Giveon",
          text: "CTO, Co-Founder",
          subText:
            "BSc in Computer Science & Economics from Tel Aviv University.\nYuval is leading Neema’s technology and product. Vast experience in the IL/US tech industry.",
        },
        {
          title: "Adv Asaf Zucker",
          text: "CBO",
          subText:
            "LLB, accounting and MBA from Tel Aviv University.\nAsaf is leading Neema’s global expansion.",
        },
        {
          title: "Adv Shira Meoded",
          text: "Compliance & Risk Officer",
          subText:
            "LLB, LLM, from Bar Ilan University. Former Citi Bank & KPMG, Shira is leading Neema’s legal, compliance and risk management teams.",
        },
      ],
    },
    section3: [
      { text: "Bank Hapoalim", subText: "Israel's largest bank" },
      { text: "Discount Bank", subText: "One of the top 3 Israeli banks" },
      {
        text: "Direct Insurance Financial Investments",
        subText:
          "Leading financing and insurance company.\nPioneers in digitizing legacy industries",
      },
    ],
  },
};
