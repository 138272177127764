import { apiClient } from "@/lib/api-client";

import { useMutation } from "@tanstack/react-query";

type CreateJobOptions = {
  fullName: string;
  businessEmail: string;
  companyName: string;
  phoneNumber: string;
  description: string;
};

export const createPayoutPortalRegistrationRequest = ({
  fullName,
  description,
  phoneNumber,
  businessEmail,
  companyName,
}: CreateJobOptions): Promise<any> => {
  return apiClient.post(`/registration/request`, {
    fullName: fullName,
    companyName: companyName,
    businessEmail: businessEmail,
    phoneNumber: phoneNumber,
    description: description,
  });
};

type UseCreateJobOptions = {
  onSuccess: () => void;
  onFailure: () => void;
  request: requestType;
};

type requestType = {
  fullName: string;
  businessEmail: string;
  companyName: string;
  phoneNumber: string;
  description: string;
};

export const useCreatePayoutPortalRegistrationRequest = ({
  onSuccess,
  onFailure,
  request,
}: UseCreateJobOptions) => {
  const { mutate: submit, isLoading } = useMutation({
    mutationFn: () =>
      createPayoutPortalRegistrationRequest({
        businessEmail: request?.businessEmail,
        description: request?.description,
        companyName: request?.companyName,
        phoneNumber: request?.phoneNumber,
        fullName: request?.fullName,
      }),
    onSuccess: () => {
      if (typeof window !== "undefined") {
        window.lintrk("track", { conversion_id: 16827273 });
      }
      onSuccess();
    },
    onError: () => {
      onFailure();
    },
  });

  return { submit, isLoading };
};
