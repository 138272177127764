import { companyText } from "./pages/company-text";
import { contactUsText } from "./pages/contact-us-text";
import { developersText } from "./pages/developers-text";
import { footerText } from "./pages/footer";
import { headerText } from "./pages/header-text";
import { homePageText } from "./pages/home-page-text";
import {
  africaCountries,
  americasCountries,
  apacCountries,
  europe,
  middleEast,
  networkText,
} from "./pages/network-text";
import { notFoundPageText } from "./pages/not-found-page-text";
import { termsText } from "./pages/terms-text";

export const text = {
  homePageText,
  footerText,
  headerText,
  developersText,
  termsText,
  notFoundPageText,
  contactUsText,
  companyText,
  networkText,
  africaCountries,
  americasCountries,
  middleEast,
  europe,
  apacCountries,
};
