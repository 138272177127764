export type Button_prop = {
  text: string;
  color: BUTTON_COLORS;
  onChange: () => void;
  backgrounColor: BUTTON_COLORS;
};

export type BUTTON_COLORS_GROUP = {
  WHITE: WHITE;
  PURPLE: PURPLE;
};

export const buttons_colors: BUTTON_COLORS_GROUP = {
  PURPLE: "PURPLE",
  WHITE: "WHITE",
};

export type PURPLE = "PURPLE";
export type WHITE = "WHITE";
export type BUTTON_COLORS = PURPLE | WHITE;
