import styles from "./Header.module.scss";
import cStyles from "../../../Component/Page.module.scss";
import cx from "classnames";
import { HeaderProp, webPage } from "../../../asset/type/type";
import { text } from "../../../asset/text/text";
import {
  isGreenVersion,
  isPurpleHeaderVersion,
  isTransperentVersion,
  isWebVersionMenue,
  isWhiteHeaderVersion,
} from "@/AppContainer/AppWrapper/utiles";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
const sectionText = text.headerText;

export const Header: React.FC<HeaderProp> = ({ onStartPage, pageId }) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.white]: isWhiteHeaderVersion(pageId, onStartPage),
        [styles.green]: isGreenVersion(pageId, onStartPage),
        [styles.purpl]: isPurpleHeaderVersion(pageId, onStartPage),
        [styles.transperent]: isTransperentVersion(pageId, onStartPage),
        [styles.webVersionMenu]: isWebVersionMenue(pageId),
      })}
    >
      <div className={styles.firstSide}>
        <div
          onClick={() => navigate(webPage.homePage.url)}
          className={cx(cStyles.logo, {
            [cStyles.green]: isWhiteHeaderVersion(pageId, onStartPage),
            [cStyles.white]:
              isGreenVersion(pageId, onStartPage) ||
              isPurpleHeaderVersion(pageId, onStartPage),
          })}
        ></div>

        <div className={styles.menu}>
          {sectionText.pages.map((page, index) => (
            <div
              key={page.url.id}
              onClick={() => {
                navigate(page.url.url);
              }}
              className={cx(styles.page, {
                [styles.extended_page]:
                  index === 3 && !isWhiteHeaderVersion(pageId, onStartPage),
                [styles.extended_page_black]:
                  index === 3 && isWhiteHeaderVersion(pageId, onStartPage),
              })}
            >
              {page.text}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.secondSide}>
        <div
          onClick={() => {
            if (typeof window !== "undefined") {
              window.lintrk("track", { conversion_id: 16827281 });
            }
            window.location.href = webPage.pay_out_portal.url;
          }}
          className={styles.login}
        >
          {sectionText.login}
        </div>
        <div
          onClick={() => {
            const nextUrl = searchParams.get("next");
            const nextPage = Object.values(webPage).find(
              (item) => item.id === nextUrl
            );

            pageId === webPage.map_site.id
              ? navigate(
                  nextUrl && nextPage ? nextPage.url : webPage.homePage.url
                )
              : navigate(`${webPage.map_site.url}?next=${pageId}`);
          }}
          className={cx(styles.mobile_menu_Image, {
            [styles.menu_image]: pageId === webPage.map_site.id,
          })}
        ></div>
        <div className={styles.buttonWrapper}>
          <div
            onClick={() => {
              if (typeof window !== "undefined") {
                window.lintrk("track", { conversion_id: 16827241 });
              }
              navigate(webPage.contact_us.url);
            }}
            className={cx(cStyles.button, {
              [cStyles.version3]: isPurpleHeaderVersion(pageId, onStartPage),

              [cStyles.version2]: isWhiteHeaderVersion(pageId, onStartPage),

              [cStyles.version5]:
                isTransperentVersion(pageId, onStartPage) ||
                isGreenVersion(pageId, onStartPage),

              [cStyles.version1]: isTransperentVersion(pageId, onStartPage),
            })}
          >
            {sectionText.ContactUs}
          </div>
        </div>
      </div>
    </div>
  );
};
