import cStyles from "../../../../../../Component/Page.module.scss";
import cx from "classnames";
import { Button_prop } from "./types/button-types";
import { getButtonStyle } from "./utiles";

export const ButtonItem: React.FC<Button_prop> = ({
  text,
  color,
  backgrounColor,
  onChange,
}) => {
  return (
    <div
      key={text}
      onClick={onChange}
      className={cx(cStyles.button, {
        [getButtonStyle(color, backgrounColor)]: true,

        [cStyles.version_big]: true,
      })}
    >
      {text}
    </div>
  );
};
