import { BUTTON_COLORS, buttons_colors } from "./types/button-types";
import cStyles from "../../../../../../Component/Page.module.scss";
export function getButtonStyle(
  color: BUTTON_COLORS,
  backgrounColor: BUTTON_COLORS
) {
  if (color === buttons_colors.WHITE) {
    if (backgrounColor === buttons_colors.PURPLE) {
      return cStyles.version2;
    }
  }
  throw new Error("Function not implemented.");
}
