import styles from "./Navigator_mobile.module.scss";

import cx from "classnames";
import { Empty, HeaderProp } from "../../../asset/type/type";
import { text } from "../../../asset/text/text";
import { isWhiteHeaderVersion } from "@/AppContainer/AppWrapper/utiles";
import { useNavigate } from "react-router-dom";
const sectionText = text.headerText;

export const Navigator_mobile: React.FC<Empty> = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        {" "}
        {sectionText.pages_mobile.map((page, index) => (
          <div
            onClick={() => {
              if (page.url.url.includes("http")) {
                window.location.href = page.url.url;
              } else {
                navigate(page.url.url);
              }
            }}
            className={cx(styles.page, {
              [styles.extended_page]: index === 3,
              [styles.extended_page_black]: index === 3,
            })}
          >
            {page.text}
          </div>
        ))}
      </div>
    </div>
  );
};
