import React from "react";
import { Helmet } from "react-helmet";
import { ReactHelmetProp } from "./asset/type/type";

export const ReactHelmet: React.FC<ReactHelmetProp> = ({
  title,
  description,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Additional head elements */}
    </Helmet>
  );
};

/*
The title is shown as the link text in Google search results, and also in browser tabs. A length of 50-60 characters is ideal for the title tag.

<meta name='description' content='Description goes here' />
The meta description is displayed underneath the title in Google search results. Since Google usually truncates descriptions at around 155-160 characters, it’s considered optimal to stay within this range.
*/
