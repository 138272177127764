import {
  Empty,
  Section2WhyChooseNeemaItemProp,
  whyChooseNeemaMember,
  WhyChooseNeemaMemberType,
} from "../../../../asset/type/type";

import cStyles from "../../../../Component/Page.module.scss";

import { Helmet } from "react-helmet";

import styles from "./Section2WhyChooseNeema.module.scss";

import cx from "classnames";

import { useContext, useState } from "react";

import { text } from "@/asset/text/text";
import { Mobile_slide } from "@/components/mobile-slide/mobile-slide";
import { App_wrapper_provider } from "@/AppContainer/AppWrapper/AppWrapper";

//import { footerText } from "../../../../asset/text/footer";

const section2WhyChooseNeemaText = text.homePageText.section2;
export const Section2WhyChooseNeema: React.FC<Empty> = () => {
  const finishRender = useContext(App_wrapper_provider).finishRender;

  return (
    <div className={cx(styles.wrapper, { [styles.animation]: finishRender })}>
      <Mobile_slide
        button_text={section2WhyChooseNeemaText.button}
        title={section2WhyChooseNeemaText.title}
        items={Object.values(section2WhyChooseNeemaText.WhyChooseNeema)}
      />
    </div>
  );
};

export const Item: React.FC<Section2WhyChooseNeemaItemProp> = ({
  text,
  ImageElement,
}) => {
  return (
    <div className={styles.ItemWrapper}>
      {ImageElement}
      <div className={styles.textWrapper}>
        <div className={styles.title}>{text.text}</div>
        <div className={styles.text}>{text.subText}</div>
      </div>
    </div>
  );
};
