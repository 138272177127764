import { text } from "@/asset/text/text";
import { AvailalbleSendingMethodes_prop } from "../../types/explore-our-network-types";
import { methodesMap } from "../../utiles";

import styles from "./Availalble_sending_methodes.module.scss";

import bankAccountImage from "@IMAGE/bankAccountImage.svg";
import { useContext, useRef } from "react";
import useAnimation from "@/components/useAnimations";
import { App_wrapper_provider } from "@/AppContainer/AppWrapper/AppWrapper";

export const AvailalbleSendingMethodes: React.FC<
  AvailalbleSendingMethodes_prop
> = ({ methodes, isLoading }) => {
  const _ref2 = useRef<any>(null);
  const _ref = useRef<any>(null);

  useAnimation(_ref2, "opacity", "AvailalbleSendingMethodes 444", !isLoading);

  useAnimation(_ref, "opacity", "AvailalbleSendingMethodes 444", !isLoading);

  const sectionText =
    text.networkText.exploreOurNetwork.availalbleSendingMethods;

  return methodes.length !== 0 ? (
    <div ref={_ref2} className={styles.wrapper}>
      <div className={styles.title}>{sectionText.title}</div>
      <div ref={_ref} className={styles.methodes}>
        {methodes.map((item, index) => {
          const methode = methodesMap.get(item);

          return (
            <div key={index} className={styles.methodeWrapper}>
              <img
                className={styles.image}
                alt={item}
                src={methode ? methode.image : bankAccountImage}
              />
              <div className={styles.methode}>
                {methode ? methode?.text : item}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};
