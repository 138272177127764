export type Input_prop = {
  title: string;
  varient: VARIENT;
  onChange: (text: string) => void;
  value?: string;
  error: undefined | string;
  onButtonClick: boolean;
};

export type VARIENT_GROUP = {
  LARGE_BOX_V1: LARGE_BOX_V1;
  IN_LARGE_BOX_ROW: IN_LARGE_BOX_ROW;
  NORMAL_BOX_V1: NORMAL_BOX_V1;
};

export const varientGroup: VARIENT_GROUP = {
  LARGE_BOX_V1: "LARGE_BOX_V1",
  NORMAL_BOX_V1: "NORMAL_BOX_V1",
  IN_LARGE_BOX_ROW: "IN_LARGE_BOX_ROW",
};
export type IN_LARGE_BOX_ROW = "IN_LARGE_BOX_ROW";
export type LARGE_BOX_V1 = "LARGE_BOX_V1";
export type NORMAL_BOX_V1 = "NORMAL_BOX_V1";
export type VARIENT = LARGE_BOX_V1 | NORMAL_BOX_V1 | IN_LARGE_BOX_ROW;
