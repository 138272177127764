import { webPage } from "@/asset/type/type";

export const headerText = {
  login: "Log in",
  pages: [
    { text: "Network", url: webPage.network },
    { text: "Developers", url: webPage.developers },

    /* { text: "Blog" },*/
    { text: "Company", url: webPage.about_us },
  ],
  pages_mobile: [
    { text: "Network", url: webPage.network },
    { text: "Developers", url: webPage.developers },

    { text: "Company", url: webPage.about_us },
    { text: "Contact us", url: webPage.contact_us },
    { text: "Login", url: webPage.pay_out_portal },
  ],

  ContactUs: "Contact us",
};
