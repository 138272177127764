import { text } from "@/asset/text/text";
import { Empty } from "@/asset/type/type";
import useAnimation from "@/components/useAnimations";
import { useRef } from "react";

import styles from "./Header.module.scss";

export const Header: React.FC<Empty> = () => {
  const _ref = useRef<any>(null);
  useAnimation(_ref, "title");
  return (
    <div className={styles.wrapper}>
      <div ref={_ref} className={styles.title}>
        {text.companyText.header_section.title}
      </div>

      <div className={styles.text}>{text.companyText.header_section.text}</div>
    </div>
  );
};
